import {useState} from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'


export const usePages = () => {
  
    const [Search, setSearch] = useState('')
    const [SelectedPg, setSelectedPg] = useState(0)
    const [MaxPg, setMaxPg] = useState(0)
    const [RegsPg, setRegsPg] = useState(10)
    const [Loading, setLoading] = useState(false)
    const [Loadito, setLoadito] = useState(false)

    const [ResData, setResData] = useState()
   
    const Regs = (Reg, url) =>{
      setRegsPg(Reg)
      GetPages(url)
      
    }

    const GetPages = async(url) =>{
      // console.log(url)
        
        setLoading(true)
        await axios.get(url)
        .then(res=>{
            setSelectedPg(res.data.paginaActual === undefined ? 0 : res.data.paginaActual)
            setMaxPg(res.data.totalPaginas === undefined ? 0 : res.data.totalPaginas)
            setRegsPg(res.data.registrosPorPagina === undefined ? 10 : res.data.registrosPorPagina)
            setResData(res.data.resultado)
            setLoading(false)
            setLoadito(false)
            // console.log(res, 'Data')
        })

        .catch(err =>{

          setLoading(false)
          console.log(err.response)

          if (err.message === 'Network Error') {
            Swal.fire({
              title: 'Error',
              text: `Fallo de conexion a internet`,
              icon: 'error',
              position: 'top',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#52C150'
            })
          }else{
            Swal.fire({
              title: 'Error',
              text: `${err.message}`,
              icon: 'error',
              position: 'top',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#52C150'
            })
          }
        })
    }

    

const  test = () =>{
  
}



  return {
    setSelectedPg, SelectedPg,
    setRegsPg, RegsPg,
    setSearch, Search,
    setLoading, Loading, 
    setLoadito, Loadito,
    GetPages, Regs, setSelectedPg,
    ResData, MaxPg, test,
  }
}

