import styled from "styled-components";

const LayoutContainer = styled.div`
height:100%;

.privater{
    overflow: hidden;
    height: 100vh;
}

.Container-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #D9D9D9;
    overflow-y: scroll;
    height: 100%;
    border-bottom: 3px solid gray;
    border-top: 3px solid gray;
    box-shadow: inset 0px 0px 12px 2px rgb(128 128 128);
}

.privater-body {
    display: flex;
    justify-content: flex-end;
    background-color: #D9D9D9;
}

.Dashboard{
    
}

.App {
    flex: 9;
    height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
.App-body{
    flex: 8.5;
    position: relative;
}


.hello-user{
    text-align: left;
    margin-left: 10px;
    padding-top: 10px;
}

.footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #f0f1f3;
    padding: 0px 2%;
    flex: 0.5;
}


.Header{
    display: flex;
    background: #F0F1F3;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    flex: 1;
}
.usr{
    display: flex;
    flex-direction: row;
    background: #9597A7;
    height: 100%;
    border-radius: 10px 0px 0px 30px;
    /* width: 140px; */
    align-items: center;
    justify-content: flex-end
}
.img-user{
    margin: 0px 15px;
    height: 65px;   
}
.btn-updown{
    height: 22px;
    cursor: pointer;
    margin: 0px 10px 0px 5px;
}
.slct-sucursal{
    
    padding: 15px 0px 15px 10px;
    align-self: center;
    font-size: 18px;
    color: #666770;
    width: 16%;
    margin-left: 5%;

    
}
.custom-select{
    width: 200px;
    height: 35px;
    margin-left: 20px;
    border: 1px solid #666770;

}
.flex-select{
    background: #F0F1F3;
    height: 100%;
    width: 75%;
}
.div-selected{
    background: #DBDDED;
    position: absolute;
    width: 200px;
    margin-left: -1px;
    border: 1px solid #666770;
    z-index: 1;
    
}
.option-selection{
    text-align: left;
    margin-left: 5px;
    height: 100%;
    padding-top: 8px;
}
ul{
    list-style: none;
}
.btn-selection{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 35px;
    width: 35px;
    background: #9597A7;
    margin-left: 165px;
    margin-top: -43px;
}
.user-updown{
    height: 14px;
    margin: 0px 10px;
}
.lista{
    padding: 0;
    margin: 0;
}
.elemento-lista{
    padding: 5px;
}
.elemento-lista:hover{
    cursor: pointer;
    background: #bfc1d4;
}
.txt-usr{
    color: white;
    font-size: 22px;
    margin: 0px 5px 0px 10px;
}



@media only screen and (max-width: 300px) {
    display: none;
}
@media only screen and (max-width: 600px) {}
@media only screen and (min-width: 600px) {}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}

`


const LayoutSidebar = styled.div`

@media only screen and (min-width: 300px) {
    display: none;
}
@media only screen and (max-width: 600px) {}
@media only screen and (min-width: 600px) {}
@media only screen and (min-width: 768px) {
    display:block;
    height: 100vh;
    flex: 1;
    background: #F0F1F3;
    user-select: none;

    

.logo{
    display: flex;
    justify-content: center;
    align-items: center;
}
.side-logo{
    height: 10vh;
    display: block;
    margin: auto;
    width: 80%;
}

.Sidebar{
    
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;

}

.element{
    border-radius: 15px;
    display: block;
    margin: auto;
    padding: 5px 15px;
}
.side-element {
    flex: 1;
    display: flex;
    width: 100%;
    margin: 5% 0% auto;
    cursor: pointer;
}
.selected-element{
    
    background: #d4e7d4;
    border-radius: 15px;
    display: block;
    margin: auto;
    padding: 5px 15px;
    width: 50%;

}

.last-element{
    
    background: #B4DBB0;
    border-radius: 15px;
    box-shadow: inset 0px 0px 10px rgb(128 128 128);
    display: block;
    margin: auto;
    padding: 5px 15px;
    width: 50%;

}

.txt-element{
  font-size: 15px;
  text-align: center;
  font-weight: bold;
  color: #9597A7;
}
.txt-element-active{
  color: #40A859;
}
.img {
    display: block;
    height: 50px;
    margin: auto;
    width: 55px;
}

.submenu-catalogo{
    position: absolute;
    background: #B4DBB0;
    width: 10vw;
    margin: 0% 0% 0% 10%;
    list-style: none;
    color: #666770;
    font-weight: 630;
    font-size: 18px;   
    z-index: 1; 
}
.sm-catalogo{
    padding: 8px;
}
.sm-catalogo:hover{
    background-color: #40A859;
    color: white;
    width: 11vw;
    cursor: pointer;
}

/* CSS para documentacion */
.submenu-documentacion{
    position: absolute;
    background: #B4DBB0;
    width: 14vw;
    margin: 0% 0% 0% 10%;
    list-style: none;
    color: #666770;
    font-weight: 630;
    font-size: 18px;   
    z-index: 1; 
}
.sm-documentacion{
    padding: 8px;
}


.sm-documentacion:hover{
    background-color: #40A859;
    color: white;
    width: 14vw;
    cursor: pointer;
}
.sm-documentacion:hover{
    background-color: #40A859;
    color: white;
    width: 14vw;
    cursor: pointer;
}



/* CSS para administracion */
.submenu-administracion{
    position: absolute;
    background: #B4DBB0;
    width: 11vw;
    margin: 0% 0% 0% 10%;
    list-style: none;
    color: #666770;
    font-weight: 630;
    font-size: 18px;
    z-index: 1; 
}
.sm-administracion{
    padding: 8px;
}


.sm-administracion:hover{
    background-color: #40A859;
    color: white;
    width: 12vw;
    cursor: pointer;
}
.sm-administracion:hover{
    background-color: #40A859;
    color: white;
    width: 12vw;
    cursor: pointer;
}


/* CSS para consulta */
.submenu-consulta{
    position: absolute;
    background: #B4DBB0;
    width: 13vw;
    margin: -9.2% 0% 0% 10%;
    list-style: none;
    color: #666770;
    font-weight: 630;
    font-size: 18px;    
    z-index: 1; 
}
.sm-consulta{
    padding: 8px;
}


.sm-consulta:hover{
    background-color: #40A859;
    color: white;
    width: 14vw;
    cursor: pointer;
}
.sm-consulta:hover{
    background-color: #40A859;
    color: white;
    width: 14vw;
    cursor: pointer;
}
}
@media only screen and (min-width: 992px) {}
`



const LayoutCanvas = styled.div`
    height: 100%;

canvas {
    background: #ffffff;    
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 4px solid gray

}
.titulCinta{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background: #151b4e;
    padding: 0.5rem;
    border-radius: 6px;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    user-select: none;
  }

  .container-firma {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90%;
}
    
.areaFirmar {
    margin-top: 4%;
    height: 75%;
}

.btnsFirmar {
    /* margin-top: 5%; */
    height: 20%;
    width: 90%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    user-select: none;
}


`
const LayoutCstmInpt = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;

input.inpt-cstm {
    outline: none;
    padding: 2px;
    border: 2px solid gray;
    border-bottom: 3px solid #151b4e;
    border-radius: 4px;
    width: 99%;
}

`
const BtnLayout = styled.div`
    display: flex;
    justify-content: center;
    width: 15%;
    height: 5vh;

.green {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
    background: #3BAF29;
    /* padding: 0px 20px; */
    border-radius: 5px;
    border: 1px solid #696969;
}
.red {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
    background: #F40000;
    /* padding: 0px 20px; */
    border-radius: 5px;
    border: 1px solid #696969;
}
.txt-btnDescript {
    text-align: center;
    margin: 0;
    color: #ffffff;
    font-weight: bold;
    font-size: 20px;
}
`
const LayoutCstmTable = styled.div`
    
`

const LayoutPaginator = styled.div`
    
`

const LayoutModals = styled.div`
  height:100%;
  .LoaderContainer{
    margin-top: 25%;
  }
  

  .headCinta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background: #151b4e;
    padding: 0.5rem;
    border-radius: 6px;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .headCinta-Units {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background: #151b4e;
    padding: 0.5rem 0px;
    border-radius: 6px 6px 0px 0px;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
  }
  .closerImg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;
}
img.img-closer {
    width: 22px;
    cursor: pointer;
}

  .Closer {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1%;
  }
  .txt-close {
    text-align: right;
    cursor: pointer;
    color: #5e5e5e;
    font-size: 20px;
  }
  .txt-closeUnitDirs {
    text-align: right;
    cursor: pointer;
    color: #5e5e5e;
    font-size: 20px;
    width: 5%;
    text-align: center;
    color: white;
}
`
const LayoutLoader = styled.div`
/* position: absolute;
    top: 40%;
    left: 48%; */
    
.loaderImg {
  position: relative;
  width: 100px;
  margin: 80px auto;
  transform: rotate(180deg);
}
.loader img{
    height: 85%;
    width: 85%;
}
.duo {
  height: 55px;
  width: 110px;
  /* / background: hsla(0, 0%, 0%, 0.0); / */
  position: absolute;
  
}

.duo, .dot {
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.duo1 {
    right: 27px;
}

.duo2 {
  left: 20px;
}


.dot {
  width: 40px;
  height: 40px;
  /* / border-radius: 100%; / */
  position: absolute;
}

.dot-a {
  left: 0px;
  /* / background: #3BAF29; / */
}

.dot-b {
  right: 0px;
  /* / background: #151B4E; / */

}


@keyframes spin {
    0% { transform: rotate(0deg) }
   50% { transform: rotate(180deg) }
  100% { transform: rotate(180deg) }
}

@keyframes onOff {
    0% { opacity: 0; }
   49% { opacity: 0; }
   50% { opacity: 1; }
  100% { opacity: 1; }
}

.duo1 {
  animation-name: spin;
}

.duo2 {
  animation-name: spin;
  animation-direction: reverse;
}

.duo2 .dot-b {
  animation-name: onOff;
}

.duo1 .dot-a {
  opacity: 0;
  animation-name: onOff;
  animation-direction: reverse;
}
.loaderTexto{
    display: flex;
    justify-content: center;
    color: #3BAF29;
    font-weight: bold;
    font-size: 22px;
    padding-top: 8px;
    letter-spacing: 1px;
}
`
const ContainerPreloader = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    
    

.img_cont img{
    width: 300px;
    
}
.img_cont{
    text-align: center;

}
`


export {LayoutContainer, LayoutCanvas, LayoutCstmInpt, LayoutSidebar, BtnLayout, LayoutModals, LayoutLoader, ContainerPreloader, LayoutCstmTable, LayoutPaginator}