import React, { useState } from 'react'
import InptTxt from '../components/cstm-input'
import DataTable from '../components/cstm-table'
import Paginador from '../components/cstm-paginator'

function Test() {
  const [Prueba1, setPrueba1] = useState('Test1')
  return (
    <div onClick={()=>console.log(Prueba1)}>
      <Paginador></Paginador>
    </div>
  )
}

export default Test