import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePages } from "../hooks/usePages";
import Modal from "react-modal";
import Preloader from "./preloader";
import cruPreloader from "../resources/CruPreloaderSVG.svg";
import Anloader from "../components/AnLoader";
import InptTxt from "./cstm-input";

import Contacts from "./list-ctosClte";
import Directions from "./list-dirsClte";

import "../themes/list_clientes.css";
import Cruz from "../resources/cruz.svg";
import Lupa from "../resources/lupa-white.svg";
import NextI from "../resources/next-i.svg";
import NextD from "../resources/next-d.svg";
import Vopc from "../resources/Uve.svg";
import Dot from "../resources/dot.svg";
import Contactos from "../resources/people-outline.svg";
import Direcciones from "../resources/ubicator.svg";
import Editar from "../resources/edit.svg";
import Deleting from "../resources/x_deleting.svg";
import Refresh from "../resources/refresh.svg";
import ReactModal from "react-modal";

function ListClientes() {
  const {
    setSelectedPg,
    SelectedPg,
    RegsPg,
    setSearch,
    Search,
    Loading,
    Loadito,
    MaxPg,
    GetPages,
    Regs,
    Searching,
    ResData,
    test,
    Timer,
  } = usePages();
  const navigate = useNavigate();

  const [ActiveDots, setActiveDots] = useState();
  const [ActiveRegs, setActiveRegs] = useState(false);
  const [ModlContacts, setModlContacts] = useState(false);
  const [ModlDirections, setModlDirections] = useState(false);

  const [ClientData, setClientData] = useState(null);

  const customSty_Contacts = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none ",
      height: "85%",
      width: "80%",
      background: "#D9D9D9",
      overflow: "none",
      boxShadow: " 0px 0px 10px rgb(128 128 128)",
    },
  };
  const customSty_Directions = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none ",
      height: "85%",
      width: "80%",
      background: "#D9D9D9",
      overflow: "none",
      boxShadow: " 0px 0px 10px rgb(128 128 128)",
    },
  };

  const cstmTest = {
      // background: "pink",

  }

  const openModalDirections = (data) => {
    setClientData(data);
    setModlDirections(true);
    setActiveDots(false);
  };

  const openModalContacts = (data) => {
    setClientData(data);
    setModlContacts(true);
    setActiveDots(false);
  };
  // const openModal = () =>{
  //     setClientData(data)

  // }

  const closeModal = () => {
    setModlContacts(false);
    setModlDirections(false);
  };

  const DotsClicked = (data) => {
    if (data === ActiveDots) {
      setActiveDots("");
    } else {
      setActiveDots(data);
    }
  };
  const RegsClicked = (data) => {
    Regs(
      data,
      `${process.env.REACT_APP_API_URL}Cliente/paginator/all?search=${Search}&page=1&registerbypage=${data}`
    );
    setActiveRegs(false);
    setActiveDots("");
  };

  const NextPg = (Pg) => {
    if (Pg < MaxPg) {
      Pg += 1;
      setSelectedPg(Pg);
      setActiveDots("");
      GetPages(
        `${process.env.REACT_APP_API_URL}Cliente/paginator/all?search=${Search}&page=${Pg}&registerbypage=${RegsPg}`
      );
    }
  };
  const PrevPg = (Pg) => {
    if (Pg > 1) {
      Pg -= 1;
      setSelectedPg(Pg);
      setActiveDots("");
      GetPages(
        `${process.env.REACT_APP_API_URL}Cliente/paginator/all?search=${Search}&page=${Pg}&registerbypage=${RegsPg}`
      );
    } else {
    }
  };

  const ProNextPg = () => {
    if (SelectedPg < MaxPg) {
      setSelectedPg(MaxPg);
      setActiveDots("");
      GetPages(
        `${process.env.REACT_APP_API_URL}Cliente/paginator/all?search=${Search}&page=${MaxPg}&registerbypage=${RegsPg}`
      );
    }
  };
  const ProPrevPg = () => {
    if (SelectedPg > 1) {
      setSelectedPg(1);
      setActiveDots("");
      GetPages(
        `${process.env.REACT_APP_API_URL}Cliente/paginator/all?search=${Search}&page=1&registerbypage=${RegsPg}`
      );
    }
  };

  const GoNav = (nav) => {
    // console.log(nav, "navigation");
    navigate(nav);
  };

  useEffect(() => {
    GetPages(
      `${process.env.REACT_APP_API_URL}Cliente/paginator/all?search=&page=1&registerbypage=10`
    );
  }, []);

  // console.log(ResData,'resdatalistclte')

  return (
    <>
      {Loading === true ? (
        <>
          <div className="Cont-Preloader">
            <Anloader tittle="Cargando clientes" />
          </div>
        </>
      ) : (
        <div className="Container-body">
          <h1 className="display-tittle">Clientes</h1>

          <div className="tools">
            <div className="search-client">

              {/* <InptTxt cstmSty={cstmTest} Tittle={'Buscar cliente'} value={Search} setValue={setSearch} ></InptTxt> */}
              <input
                className="inpt-search"
                type="text"
                value={Search}
                onChange={(e) => setSearch(e.target.value)}
              ></input>

              <div className="btn-searchClient">
                <button
                  className="img-search"
                  onClick={() =>
                    GetPages(
                      `${process.env.REACT_APP_API_URL}Cliente/paginator/all?search=${Search}&page=1&registerbypage=${RegsPg}`
                    )
                  }
                >
                  <img
                    draggable={false}
                    className="search-icon"
                    src={Search === "" ? Refresh : Lupa}
                    alt=""
                  />
                </button>
              </div>

              <img
                className="img-preloader"
                src={cruPreloader}
                alt=""
                style={Loadito ? { display: "block" } : {}}
              ></img>

              {/* <div className='list-search'></div> */}
            </div>
            <button
              className="btn-AddClient"
              onClick={() => GoNav("/Agregar-cliente")}
            >
              <img
                draggable={false}
                className="image-AddClient"
                src={Cruz}
                alt=""
              />
            </button>
          </div>

          <div className="table-container">
            <div className="table">
              <div className="table-head">
                <div className="tblsize-id">
                  <p className="txt-tblhead">ID</p>
                </div>

                <div className="tblsize-sucursal">
                  <p className="txt-tblhead">Sucursal</p>
                </div>

                <div className="tblsize-nombre">
                  <p className="txt-tblhead">Nombre</p>
                </div>

                <div className="tblsize-rfc">
                  <p className="txt-tblhead">RFC</p>
                </div>

                <div className="tblsize-correo">
                  <p className="txt-tblhead">Correo</p>
                </div>

                <div className="tblsize-activo">
                  <p className="txt-tblhead">Estatus</p>
                </div>
                
                <div className="tblsize-credito">
                  <p className="txt-tblhead">Crédito</p>
                </div>

                <div className="tblsize-dots"></div>
              </div>
              <div className="table-body">
                <div className="row" style={ResData ? { display: "none" } : {}}>
                  <p
                    className="noclients"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    No se encontraron clientes
                  </p>
                </div>

                {ResData?.map((Clients, indx) => {
                  return (
                    <div className="row">
                      <div className="tblsize-id">
                        <p className="txt-tableInfo">{`${Clients.id}`}</p>
                      </div>

                      <div className="tblsize-sucursal">
                        <div className="container-branch">
                          <p className="txt-tableInfo" style={{textAlign:'left'}}>{`${Clients.nombreSucursal}`}</p>
                        </div>
                      </div>

                      <div className="tblsize-nombre">
                        <div className="container-name">
                          <p
                            className="txt-tableInfo"
                            style={{ textAlign: "left" }}
                          >{`${Clients.nombrecompleto}`}</p>
                        </div>
                      </div>

                      <div className="tblsize-rfc">
                        <p className="txt-tableInfo">{`${Clients.rfc}`}</p>
                      </div>

                      <div className="tblsize-correo">
                        <p
                          className="txt-tableInfo"
                          style={{ textAlign: "left" }}
                        >{`${Clients.correo}`}</p>
                      </div>

                      <div className="tblsize-activo">
                        <div className="container-activo" style={Clients.activo === 'Si' ? {background:'#3aae29'} : {background:'#e92828'}}>
                          <p className="txt-activo">
                            {Clients.activo === "Si" ? "Activo" : "Inactivo"}
                          </p>
                        </div>
                      </div>

                      
                      <div className="tblsize-credito">
                        <div className="container-credito" style={Clients.credito === 'Si' ? {background:'#3aae29'} : {background:'#e92828'}}>
                          <p className="txt-credito">
                            {Clients.credito}
                          </p>
                        </div>
                      </div>

                      <div className="tblsize-dots">
                        <div
                          className={
                            ActiveDots === Clients.id
                              ? "dots-active"
                              : "dots-container"
                          }
                          onClick={() => DotsClicked(Clients.id)}
                        >
                          <span classname="dooots"></span>
                          <span classname="dooots"></span>
                          <span classname="dooots"></span>
                           {/* <img
                              draggable={false}
                              classname="dooots"
                              src={Dot}
                              alt=""
                            />
                            <img
                              draggable={false}
                              classname="dooots"
                              src={Dot}
                              alt=""
                            />
                            <img
                              draggable={false}
                              classname="dooots"
                              src={Dot}
                              alt=""
                            /> */}
                            
                        </div>
                        <div
                          className="dots-menu"
                          style={
                            ActiveDots === Clients.id
                              ? // ? indx > (ResData.length - 5)
                                // ResData.length > 6 ?
                                // indx > (ResData.length > 6 ? ResData.length - 5)
                                (
                                  ResData.length > 7
                                    ? indx > ResData.length - 5
                                    : false
                                )
                                ? { display: "block", bottom: "36px" } //Se despliega el submenu hacia arriba
                                : { display: "block", top: "36px" } //Se despliega en submenu hacia abajo
                              : { display: "none" }
                          }
                          onMouseLeave={()=>setActiveDots('')}
                        >
                          <div
                            className="sm-row"
                            onClick={() => GoNav(`/Cliente-contactos/${Clients.id}`)}
                          >
                            <div className="sm-img">
                              <img
                                draggable={false}
                                className="sm-icon"
                                src={Contactos}
                                alt=""
                              />
                            </div>
                            <div className="sm-txt">
                              <p className="txt-submenu">Contactos</p>
                            </div>
                          </div>

                          <div
                            className="sm-row"
                            onClick={() =>
                              GoNav(`/Cliente-direcciones/${Clients.id}`)
                            }
                          >
                            <div className="sm-img">
                              <img
                                className="sm-icon"
                                src={Direcciones}
                                alt=""
                              />
                            </div>
                            <div className="sm-txt">
                              <p className="txt-submenu">Direcciones</p>
                            </div>
                          </div>

                          <div
                            className="sm-row"
                            onClick={() => GoNav(`/Editar-cliente/${Clients.id}`)}
                          >
                            <div className="sm-img">
                              <img
                                draggable={false}
                                className="sm-icon"
                                src={Editar}
                                alt=""
                              />
                            </div>
                            <div className="sm-txt">
                              <p className="txt-submenu">Editar cliente</p>
                            </div>
                          </div>

                          <div className="sm-row">
                            <div
                              className="sm-img"
                              style={{ background: "#F96262" }}
                            >
                              <img
                                draggable={false}
                                className="sm-icon"
                                src={Deleting}
                                alt=""
                              />
                            </div>
                            <div className="sm-txt">
                              <p className="txt-submenu">
                                Solicitar eliminación
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="seccion-paginador">
              <div className="registros">
                <div className="regs-slctContainer">
                  <p className="txt-regs">Registros</p>

                  <div
                    className="regs-contained"
                    onMouseLeave={() => setActiveRegs(false)}
                  >
                    <div
                      className="regs-pg"
                      style={ActiveRegs ? {} : { borderRadius: "10px" }}
                    >
                      <div className="regs-selected">
                        <p className="txt-regs">{`${RegsPg}`}</p>
                      </div>
                      <div
                        className="regs-selection"
                        onClick={() => setActiveRegs(!ActiveRegs)}
                        style={
                          ActiveRegs ? { borderRadius: "0px 0px 8px 0px" } : {}
                        }
                      >
                        <img
                          className="regs-select"
                          src={Vopc}
                          alt=""
                          style={
                            ActiveRegs ? { transform: "rotate(180deg)" } : {}
                          }
                        />
                      </div>
                    </div>
                    <div className={ActiveRegs ? "regs-active" : "regs-list"}>
                      <div
                        className="reg"
                        onClick={() => RegsClicked(10)}
                        style={RegsPg === 10 ? { display: "none" } : {}}
                      >
                        10
                      </div>
                      <div
                        className="reg"
                        onClick={() => RegsClicked(15)}
                        style={RegsPg === 15 ? { display: "none" } : {}}
                      >
                        15
                      </div>
                      <div
                        className="reg"
                        onClick={() => RegsClicked(20)}
                        style={RegsPg === 20 ? { display: "none" } : {}}
                      >
                        20
                      </div>
                      <div
                        className="reg"
                        onClick={() => RegsClicked(25)}
                        style={RegsPg === 25 ? { display: "none" } : {}}
                      >
                        25
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="paginador">
                <div
                  className="prev-plus"
                  onClick={() => ProPrevPg()}
                  style={
                    SelectedPg === 1
                      ? { background: "#9597a73d", cursor: "unset" }
                      : {}
                  }
                >
                  <img draggable={false} src={NextI} alt="" />
                  <img draggable={false} src={NextI} alt="" />
                </div>
                <div
                  className="prev"
                  onClick={() => PrevPg(SelectedPg)}
                  style={
                    SelectedPg === 1
                      ? { background: "#9597a73d", cursor: "unset" }
                      : {}
                  }
                >
                  <img draggable={false} src={NextI} alt="" />
                </div>
                <div
                  className="pg"
                  onClick={() => test()}
                >{`${SelectedPg} / ${MaxPg}`}</div>
                <div
                  className="next"
                  onClick={() => NextPg(SelectedPg)}
                  style={
                    SelectedPg === MaxPg
                      ? { background: "#9597a73d", cursor: "unset" }
                      : {}
                  }
                >
                  <img draggable={false} src={NextD} alt="" />
                </div>
                <div
                  className="next-plus"
                  onClick={() => ProNextPg()}
                  style={
                    SelectedPg === MaxPg
                      ? { background: "#9597a73d", cursor: "unset" }
                      : {}
                  }
                >
                  <img draggable={false} src={NextD} alt="" />
                  <img draggable={false} src={NextD} alt="" />
                </div>
              </div>
            </div>
          </div>

          <Modal
            isOpen={ModlContacts}
            style={customSty_Contacts}
            onRequestClose={closeModal}
          >
            <Contacts
              closeModal={closeModal}
              ClientData={ClientData}
            ></Contacts>
          </Modal>

          <Modal
            isOpen={ModlDirections}
            style={customSty_Directions}
            onRequestClose={closeModal}
          >
            <Directions
              closeModal={closeModal}
              ClientData={ClientData}
            ></Directions>
          </Modal>
        </div>
      )}
    </>
  );
}

export default ListClientes;
