import React, { useEffect, useState } from "react";
import { usePages } from "../hooks/usePages";
import { useParams } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
import UnitDirection from "./Modl-UnitDirection";

import NextI from "../resources/next-i.svg";
import NextD from "../resources/next-d.svg";
import Vopc from "../resources/Uve.svg";
import Dot from "../resources/dot.svg";
import Cruz from "../resources/cruz.svg";
import lupa from "../resources/lupa-white.svg";
import Deleting from "../resources/x_deleting.svg";
import AddImg from "../resources/add-image.png";
import Editar from "../resources/edit.svg";

import Anloader from "./AnLoader";
import ReactModal from "react-modal";
import X from "../resources/Equis.svg";

function Directions() {
  const customSty_UnitDirection = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none ",
      height: "90%",
      width: "75%",
      background: "#D9D9D9",
      overflow: "none",
      boxShadow: " 0px 0px 10px rgb(128 128 128)",
    },
  };

  const {
    setSelectedPg,
    SelectedPg,
    RegsPg,
    MaxPg,
    GetPages,
    Regs,
    ResData,
    test,
  } = usePages();

  
  const Param = useParams();

// const [EditMode, setEditMode] = useState(false)
  const [ClteID, setClteID] = useState(Param.id);
  const [Data, setData] = useState();
  const [ModlData, setModlData] = useState();
  const [ActiveRegs, setActiveRegs] = useState(false);

  const [ModlUnitDirecs, setModlUnitDirecs] = useState(false);
  const [ActiveDots, setActiveDots] = useState(false);
  const [LoadingDirections, setLoadingDirections] = useState(false);

  const [IsosCountrys, setIsosCountrys] = useState()

  const openModalUnitDirection = (modalidad) => {
    setModlUnitDirecs(true);
    setModlData({ clientData: Data, Mode: modalidad });
    setActiveDots('')
  };

  const closeModal = () => {
    setModlUnitDirecs(false);
  };

  const DotsClicked = (data) => {
    if (data === ActiveDots) {
      setActiveDots("");
    } else {
      setActiveDots(data);
    }
  };

  const NextPg = (Pg) => {
    if (Pg < MaxPg) {
      setLoadingDirections(true)
      Pg += 1;
      setSelectedPg(Pg);
      setActiveDots("");
      GetPages(
        `${process.env.REACT_APP_API_URL}Cliente/Address/Get/${Data.id}?page=${Pg}&registerbypage=${RegsPg - 1}`);
    }
    
    setLoadingDirections(false)
  };
  const PrevPg = (Pg) => {
    if (Pg > 1) {
      Pg -= 1;
      setSelectedPg(Pg);
      setActiveDots("");
      GetPages(
        `${process.env.REACT_APP_API_URL}Cliente/Address/Get/${
          Data.id
        }?page=${Pg}&registerbypage=${RegsPg - 1}`
      );
    } else {
    }
  };

  const ProNextPg = (url) => {
    if (SelectedPg < MaxPg) {
      setSelectedPg(MaxPg);
      GetPages(url);
    }
  };
  const ProPrevPg = (url) => {
    if (SelectedPg > 1) {
      setSelectedPg(1);
      GetPages(url);
    }
  };

  const RegsClicked = (data) => {
    Regs(
      data,
      `${process.env.REACT_APP_API_URL}Cliente/Address/Get/${
        Data.id
      }?page=1&registerbypage=${data - 1}`
    );
    setActiveRegs(false);
  };

  useEffect(() => {
    setLoadingDirections(true)
    axios.get(`${process.env.REACT_APP_API_URL}Cliente/Get/${ClteID}`)
      .then((res) => {
        // console.log(res, "resss");
        setData(res.data);
        
        GetPages(
          `${process.env.REACT_APP_API_URL}Cliente/Address/Get/${ClteID}?page=1&registerbypage=9`
        );
        
        setLoadingDirections(false)
      })
      .catch((err) => {
        alert(err.data.response.message);
        setLoadingDirections(false)
      });

    // axios.get(`${process.env.REACT_APP_API_URL}CodigoPostal/getIsoCountry`)
    // .then(res=>{
    //   console.log(res.data.data[0])
    // })
    // .catch(err=>{
    //   console.log(err)
    // })

  }, []);

  console.log(ResData,'resdirs')
  return (
    <>
      {LoadingDirections ? (
        <div className="LoaderContainer" style={{marginTop:'25%'}}>
          <Anloader tittle="Cargando direcciones"></Anloader>
        </div>
      ) : (
        
        <div className="container-Direcciones">
          <div className="Dirs-head">
          <p className="txt-ClteCtos">Direcciones del cliente</p>
            <div className="InfoClientTable">
              
              <div className="ClientInfo-head">
                <div className="InfoSize-id">
                  <p className="txtHead-ClientInfo">ID</p>
                </div>
                
                <div className="InfoSize-sucursal">
                  <p className="txtHead-ClientInfo">Sucursal</p>
                </div>
                
                <div className="InfoSize-nombre">
                  <p className="txtHead-ClientInfo">Nombre</p>
                </div>
                
                <div className="InfoSize-rfc">
                  <p className="txtHead-ClientInfo">RFC</p>
                </div>
                

              </div>
              <div className="ClientInfo-body">

                <div className="InfoSize-id">
                  <p className="txtBody-ClientInfo">{Data?.id}</p>
                </div>
                
                <div className="InfoSize-sucursal">
                  <p className="txtBody-ClientInfo">{Data?.nombreSucursal}</p>
                </div>
                
                <div className="InfoSize-nombre">
                  <p className="txtBody-ClientInfo">{Data?.nombrecompleto}</p>
                </div>
                
                <div className="InfoSize-rfc">
                  <p className="txtBody-ClientInfo">{Data?.rfc}</p>
                </div>
                

              </div>
            </div>

            {/* <div className="table">
              <div className="table-head">
                <div className="tblsize-id">
                  <p className="txt-tblhead">ID</p>
                </div>

                <div className="tblsize-sucursal">
                  <p className="txt-tblhead">Sucursal</p>
                </div>

                <div className="tblsize-nombre">
                  <p className="txt-tblhead">Nombre</p>
                </div>

                <div className="tblsize-rfc">
                  <p className="txt-tblhead">RFC</p>
                </div>
                
              </div>
              <div className="table-body">

                <div className="row">
                  <div className="tblsize-id">
                    <p className="txt-tableInfo">{Data?.id}</p>
                  </div>

                  <div className="tblsize-sucursal">
                    <div className="container-branch">
                      <p className="txt-tableInfo" style={{textAlign:'left'}}>{Data?.nombreSucursal}</p>
                    </div>
                  </div>

                  <div className="tblsize-nombre">
                    <div className="container-name">
                      <p
                        className="txt-tableInfo"
                        // style={{ textAlign: "left" }}
                      >{Data?.nombrecompleto}</p>
                    </div>
                  </div>

                  <div className="tblsize-rfc">
                    <p className="txt-tableInfo">{Data?.rfc}</p>
                  </div>


                </div>

              </div>
            </div> */}
            
          </div>

          <div className="Dirs-Tools">
            <div className="infos-filters">
              <div className="filter-search">
                <div className="busqueda-contactos">
                  <p>Buscar dirección</p>
                  <input
                    className="inpt-srchContacto"
                    type="text"
                    // value={SrchContact}
                    // onChange={(e) => setsrc(e.target.value)}
                  />
                </div>

                <button className="btn-srchContacts">
                  <img
                    draggable={false}
                    style={{ width: "20px" }}
                    src={lupa}
                  />
                </button>
              </div>

            </div>

            <button
              className="btn-add"
              onClick={() => openModalUnitDirection(0)}
            >
              <img
                draggable={false}
                className="image-add"
                src={Cruz}
                alt=""
              />
            </button>
          </div>

          <div className="Dirs-body">

            <div className="table-container">
              <div className="table">
                <div className="table-head">

                  <div className="tblDirs-nombre">
                    <p className="txt-tblhead">Dirección</p>
                  </div>

                  <div className="tblDirs-pais">
                    <p className="txt-tblhead">País</p>
                  </div>

                  <div className="tblDirs-dots"></div>
                </div>

                <div className="table-body">
                  <div className="row" style={ResData ? { display: "none" } : {}}>
                    <p
                      className="noclients"
                      style={{ width: "100%", textAlign: "center" }}
                    >
                      No se encontraron direcciones
                    </p>
                  </div>

                  {ResData?.map((Dirs, indx) => {
                    return (
                      <div className="row">

                        <div className="tblDirs-nombre">
                          <div className="container-name">
                            
                          <p className="txt-body" style={{ textAlign: "left" }}>
                              {Dirs.calle}
                              {Dirs.noext
                                ? `, Ext. #${Dirs.noext}`
                                : ""}
                              {Dirs.noint === "s/n" || " "
                                ? ""
                                : `, Int. #${Dirs.noint}`}
                              {Dirs.colonia 
                                ? `, ${Dirs.colonia}`
                                : ''}
                              {Dirs.localidad 
                                ? `, ${Dirs.localidad}`
                                : ''}
                            </p>
                          </div>
                        </div>

                        <div className="tblDirs-pais">
                          <p className="txt-tableInfo">{`${Dirs.pais}`}</p>
                        </div>


                        <div className="tblDirs-dots">
                          <div
                            className={
                              ActiveDots === Dirs.id
                                ? "dots-active"
                                : "dots-container"
                            }
                            onClick={() => DotsClicked(Dirs.id)}
                          >
                            
                          <span classname="dooots"></span>
                          <span classname="dooots"></span>
                          <span classname="dooots"></span>
                            {/* <img
                                draggable={false}
                                classname="dooots"
                                src={Dot}
                                alt=""
                              />
                              <img
                                draggable={false}
                                classname="dooots"
                                src={Dot}
                                alt=""
                              />
                              <img
                                draggable={false}
                                classname="dooots"
                                src={Dot}
                                alt=""
                              /> */}
                              
                          </div>
                          <div
                            className="dots-menu"
                            style={
                              ActiveDots === Dirs.id
                                ? // ? indx > (ResData.length - 5)
                                  // ResData.length > 6 ?
                                  // indx > (ResData.length > 6 ? ResData.length - 5)
                                  (
                                    ResData.length > 7
                                      ? indx > ResData.length - 5
                                      : false
                                  )
                                  ? { display: "block", bottom: "36px" } //Se despliega el submenu hacia arriba
                                  : { display: "block", top: "36px" } //Se despliega en submenu hacia abajo
                                : { display: "none" }
                            }
                          >
                            <div
                              className="sm-row"
                              onClick={() => openModalUnitDirection(1)}
                            >
                              <div className="sm-img">
                                <img
                                  draggable={false}
                                  className="sm-icon"
                                  src={Editar}
                                  alt=""
                                />
                              </div>
                              <div className="sm-txt">
                                <p className="txt-submenu">Contactos</p>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="seccion-paginador">
                <div className="registros">
                  <div className="regs-slctContainer">
                    <p className="txt-regs">Registros</p>

                    <div
                      className="regs-contained"
                      onMouseLeave={() => setActiveRegs(false)}
                    >
                      <div
                        className="regs-pg"
                        style={ActiveRegs ? {} : { borderRadius: "10px" }}
                      >
                        <div className="regs-selected">
                          <p className="txt-regs">{`${RegsPg}`}</p>
                        </div>
                        <div
                          className="regs-selection"
                          onClick={() => setActiveRegs(!ActiveRegs)}
                          style={
                            ActiveRegs ? { borderRadius: "0px 0px 8px 0px" } : {}
                          }
                        >
                          <img
                            className="regs-select"
                            src={Vopc}
                            alt=""
                            style={
                              ActiveRegs ? { transform: "rotate(180deg)" } : {}
                            }
                          />
                        </div>
                      </div>
                      <div className={ActiveRegs ? "regs-active" : "regs-list"}>
                        <div
                          className="reg"
                          onClick={() => RegsClicked(10)}
                          style={RegsPg === 10 ? { display: "none" } : {}}
                        >
                          10
                        </div>
                        <div
                          className="reg"
                          onClick={() => RegsClicked(15)}
                          style={RegsPg === 15 ? { display: "none" } : {}}
                        >
                          15
                        </div>
                        <div
                          className="reg"
                          onClick={() => RegsClicked(20)}
                          style={RegsPg === 20 ? { display: "none" } : {}}
                        >
                          20
                        </div>
                        <div
                          className="reg"
                          onClick={() => RegsClicked(25)}
                          style={RegsPg === 25 ? { display: "none" } : {}}
                        >
                          25
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="paginador">
                  <div
                    className="prev-plus"
                    onClick={() => ProPrevPg()}
                    style={
                      SelectedPg === 1
                        ? { background: "#9597a73d", cursor: "unset" }
                        : {}
                    }
                  >
                    <img draggable={false} src={NextI} alt="" />
                    <img draggable={false} src={NextI} alt="" />
                  </div>
                  <div
                    className="prev"
                    onClick={() => PrevPg(SelectedPg)}
                    style={
                      SelectedPg === 1
                        ? { background: "#9597a73d", cursor: "unset" }
                        : {}
                    }
                  >
                    <img draggable={false} src={NextI} alt="" />
                  </div>
                  <div
                    className="pg"
                    onClick={() => test()}
                  >{`${SelectedPg} / ${MaxPg}`}</div>
                  <div
                    className="next"
                    onClick={() => NextPg(SelectedPg)}
                    style={
                      SelectedPg === MaxPg || MaxPg === 0
                        ? { background: "#9597a73d", cursor: "unset" }
                        : {}
                    }
                  >
                    <img draggable={false} src={NextD} alt="" />
                  </div>
                  <div
                    className="next-plus"
                    onClick={() => ProNextPg()}
                    style={
                      SelectedPg === MaxPg
                        ? { background: "#9597a73d", cursor: "unset" }
                        : {}
                    }
                  >
                    <img draggable={false} src={NextD} alt="" />
                    <img draggable={false} src={NextD} alt="" />
                  </div>
                </div>
              </div>
            </div>
            
          </div>



          <Modal
            isOpen={ModlUnitDirecs}
            style={customSty_UnitDirection}
            onRequestClose={closeModal}
          >
            <UnitDirection
              closeModal={closeModal}
              ModlData={ModlData}
            ></UnitDirection>
          </Modal>
        </div>
      )}
    </>
  );
}

export default Directions;
