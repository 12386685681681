import React, {useState} from 'react'
import { LayoutPaginator } from '../themes/layout'
import { usePages } from '../hooks/usePages';
import NextI from '../resources/next-i.svg';
import NextD from '../resources/next-d.svg';




function Paginador(props) {
    

    const {setSelectedPg, SelectedPg, RegsPg, setSearch, Search, Loading, Loadito, MaxPg, GetPages, Regs, Searching, ResData, test, Timer} = usePages();

    // const ProPrevPg = () =>{

    // } 
    const [pagina, setPagina] = useState(1)
    const [maxpaginas, setMaxpaginas] = useState(20)

    const anterior = () =>{
        if (pagina > 1) {
            setPagina(pagina-1)
        }
    }
    const anteriorpro = () =>{
        
        setPagina(1)
    }
    const siguiente = () =>{
        
        if (pagina < maxpaginas) {
            setPagina(pagina+1)
        }
    }
    const siguientepro = () =>{
        setPagina(maxpaginas)
    }

  return (
    <LayoutPaginator>
        
        <div className="paginador">
            <div
            className="prev-plus"
            onClick={() =>
                anteriorpro()
            }
            style={
                pagina === 1 || pagina === 0
                ? { background: "#9597a73d", cursor: "unset" }
                : {}
            }>

                <img draggable={false} src={NextI} alt="" />
                <img draggable={false} src={NextI} alt="" />
            </div>

            <div
            className="prev"
            onClick={() => anterior()}
            style={
                pagina === 1 || pagina === 0
                ? { background: "#9597a73d", cursor: "unset" }
                : {}
            }>
                <img draggable={false} src={NextI} alt="" />
            </div>
            
            <div
            className="pg"
            // onClick={() => test()}
            >{`${pagina} / ${maxpaginas }`}</div>
            <div
            className="next"
            onClick={() => siguiente()}
            style={
                pagina === maxpaginas || maxpaginas === 0
                ? { background: "#9597a73d", cursor: "unset" }
                : {}
            }
            >
            <img draggable={false} src={NextD} alt="" />
            </div>
            <div
            className="next-plus"


            onClick={() =>
                siguientepro()
            }
            style={
                pagina === maxpaginas || maxpaginas === 0
                ? { background: "#9597a73d", cursor: "unset" }
                : {}
            }
            >
            <img draggable={false} src={NextD} alt="" />
            <img draggable={false} src={NextD} alt="" />
            </div>
        </div>
    </LayoutPaginator>
  )
}

export default Paginador