import Navigation from './routes/navigation';
import {LayoutContainer} from './themes/layout';

function App() {
  return (
    <LayoutContainer>
      <Navigation></Navigation>
    </LayoutContainer>
  );
}

export default App;
