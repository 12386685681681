import React, { Children } from 'react'
import { Navigate } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import Header from '../components/header'
import Footer from '../components/footer';
const PrivateRouter = ({children}) => {

    const User = true;
    if (!User) return <Navigate to='/login'/>

  return (
      <div className='privater'>
        
        <div className='privater-body'>
          <Sidebar></Sidebar>
            <div className='App'>
                <Header></Header>
                  <div className='App-body'>
                    {children}
                  </div>
                <Footer></Footer>
            </div>
        </div>
          
      </div>
  )
}

export default PrivateRouter