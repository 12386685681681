import React from "react";
import { BtnLayout } from "../themes/layout";

function Btn(props) {
  return (
    <BtnLayout style={props.style}>
      <button className={props.btnColor} onClick={() => props.Clicked()}>
        <p className="txt-btnDescript">{props.btnDescript}</p>
      </button>
    </BtnLayout>
  );
}

export default Btn;
