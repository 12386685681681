import React, { useState } from "react";
import Userimg from "../resources/Userio.png";
import Vopc from "../resources/Uve.svg";
import "../themes/header.css";

function Header() {
  const [OpcSucursales, setOpcSucursales] = useState(false);

  // const ClickedUser = () =>{
  //   setOpcSucursales(!OpcSucursales)
  // }

  return (
    <div className="Header">
      <div
        // className="custom-select"
        // onMouseLeave={() => setOpcSucursales(false)}
      >
        {/* <div className="flex-select">
          <div className="option-selection">
            <div>LMM CENTRO</div>
          </div>
          <div
            className="btn-selection"
            onClick={() => setOpcSucursales(!OpcSucursales)}
          >
            <div className="updown-select">
              <img
                draggable={false}
                className="user-updown"
                src={Vopc}
                alt=""
                style={OpcSucursales ? { transform: "rotate(180deg)" } : {}}
              />
            </div>
          </div>
        </div>

        <div
          className="div-selected"
          style={
            OpcSucursales === true ? { display: "block" } : { display: "none" }
          }
        >
          <ul className="lista">
            <li className="elemento-lista">LMM CENTRO</li>
            <li className="elemento-lista">LMM CENTRO</li>
            <li className="elemento-lista">LMM CENTRO</li>
            <li className="elemento-lista">LMM CENTRO</li>
          </ul>
        </div> */}
      </div>

      <div className="usr">
        <img draggable={false} className="img-user" src={Userimg} />
        <img
          draggable={false}
          className="btn-updown"
          style={{ cursor: "pointer" }}
          src={Vopc}
        />
      </div>
    </div>
  );
}

export default Header;
