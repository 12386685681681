import React, { useEffect, useState } from "react";
import { LayoutModals } from "../themes/layout";
import { usePages } from "../hooks/usePages";
import { useParams } from "react-router-dom";
import Anloader from "./AnLoader";
import UnitContacts from "./Modl-UnitContacts";
import Modal from "react-modal";


import axios from "axios";
import NextI from "../resources/next-i.svg";
import NextD from "../resources/next-d.svg";
import Vopc from "../resources/Uve.svg";
import Dot from "../resources/dot.svg";
import Deleting from "../resources/x_deleting.svg";
import AddImg from "../resources/add-image.png";
import Cruz from "../resources/cruz.svg";
import lupa from "../resources/lupa-white.svg";
import Ine from "../resources/INE.svg";
import Licencia from "../resources/Licence.svg";
import Editar from "../resources/edit.svg";
import X from "../resources/Equis.svg";

function Contacts() {
  
  const customSty_UnitContacto = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none ",
      height: "90%",
      width: "75%",
      background: "#D9D9D9",
      overflow: "none",
      boxShadow: " 0px 0px 10px rgb(128 128 128)",
    },
  };

  const {
    setSelectedPg,
    SelectedPg,
    RegsPg,
    MaxPg,
    GetPages,
    Regs,
    ResData,
    test,
  } = usePages();

  
  const Param = useParams();

  const [ClteID, setClteID] = useState(Param.id);
  const [Data, setData] = useState();
  const [ModlData, setModlData] = useState();
  const [LoadingContacts, setLoadingContacts] = useState(false);
  const [SrchContact, setSrchContact] = useState();

  const [ModlUnitContacs, setModlUnitContacs] = useState(false);
  const [ActiveRegs, setActiveRegs] = useState(false);
  const [ActiveDots, setActiveDots] = useState(false);

  const openModalUnitContacts = (modalidad, ctoData) => {
    console.log(ctoData)
    setModlUnitContacs(true);
    setModlData({ ContactData: ctoData, Mode: modalidad });
    setActiveDots('')
  };

  const closeModal = () => {
    setModlUnitContacs(false);
  };

  const DotsClicked = (data) => {
    if (data === ActiveDots) {
      setActiveDots("");
    } else {
      setActiveDots(data);
    }
  };

  const NextPg = (Pg) => {
    if (Pg < MaxPg) {
      setLoadingContacts(true)
      Pg += 1;
      GetPages(
        `${process.env.REACT_APP_API_URL}Identificacion/getcontacts/${Data.id}?page=${Pg}&registerbypage=${RegsPg}`
      );
      setSelectedPg(Pg);
      setActiveDots("");
    }
    setLoadingContacts(false)
  };

  const PrevPg = (Pg) => {
    if (Pg > 1) {
      setLoadingContacts(true)
      Pg -= 1;
      GetPages(
        `${process.env.REACT_APP_API_URL}Identificacion/getcontacts/${Data.id}?page=${Pg}&registerbypage=${RegsPg}`
      );
      setSelectedPg(Pg);
      setActiveDots("");
    }
    
    setLoadingContacts(false)
  };

  const ProNextPg = () => {
    if (SelectedPg < MaxPg) {
      
      setLoadingContacts(true)
      setSelectedPg(MaxPg);
      GetPages(
        `${process.env.REACT_APP_API_URL}Identificacion/getcontacts/${Data.id}?page=${MaxPg}&registerbypage=${RegsPg}`
      );
    }
    
    setLoadingContacts(false)
  };
  const ProPrevPg = () => {
    if (SelectedPg > 1) {
      
      setLoadingContacts(true)
      setSelectedPg(1);
      GetPages(
        `${process.env.REACT_APP_API_URL}Identificacion/getcontacts/${Data.id}?page=$1&registerbypage=${RegsPg}`
      );
    }

    setLoadingContacts(false)
  };

  const RegsClicked = (data) => {
    Regs(
      data,
      `${process.env.REACT_APP_API_URL}Identificacion/getcontacts/${Data.id}?page=1&registerbypage=${data}`
    );
    setActiveRegs(false);
  };

  useEffect(() => {
    setLoadingContacts(true)
    axios.get(`${process.env.REACT_APP_API_URL}Cliente/Get/${ClteID}`)
    .then((res) => {
      // console.log(res, "resss");
      setData(res.data);

      
      GetPages(
        `${process.env.REACT_APP_API_URL}Identificacion/getcontacts/${ClteID}?page=1&registerbypage=10`
      );
      setLoadingContacts(false)

    })
    .catch((err) => {
      alert(err.data.response.message);
      setLoadingContacts(false)
    });

  }, []);

  // console.log(ResData,'resdta')
  // console.log(Data ? Data : 'data')
  return (
    <>
      {LoadingContacts ? (
        <div className="LoaderContainer">
          <Anloader tittle="Cargando contactos"></Anloader>
        </div>
      ) : (
        



        
        <div className="container-Contactos">
          <div className="Ctos-head">
            <p className="txt-ClteCtos"> Contactos del cliente</p>
          <div className="InfoClientTable">
            
            <div className="ClientInfo-head">
              <div className="InfoSize-id">
                <p className="txtHead-ClientInfo">ID</p>
              </div>
              
              <div className="InfoSize-sucursal">
                <p className="txtHead-ClientInfo">Sucursal</p>
              </div>
              
              <div className="InfoSize-nombre">
                <p className="txtHead-ClientInfo">Nombre</p>
              </div>
              
              <div className="InfoSize-rfc">
                <p className="txtHead-ClientInfo">RFC</p>
              </div>
              

            </div>
            <div className="ClientInfo-body">

              <div className="InfoSize-id">
                <p className="txtBody-ClientInfo">{Data?.id}</p>
              </div>
              
              <div className="InfoSize-sucursal">
                <p className="txtBody-ClientInfo">{Data?.nombreSucursal}</p>
              </div>

              <div className="InfoSize-nombre">
                <p className="txtBody-ClientInfo">{Data?.nombrecompleto}</p>
              </div>
              
              <div className="InfoSize-rfc">
                <p className="txtBody-ClientInfo">{Data?.rfc}</p>
              </div>
              

            </div>
          </div>

          </div>

          <div className="Ctos-body">
            {/* <p className="txt-ClteCtos"> cliente</p> */}
            <div className="ContactsClientTable">
            <div className="Ctos-Tools">
              <div className="infos-filters">
                <div className="filter-search">
                  <div className="busqueda-contactos">
                    <p>Buscar contacto</p>
                    <input
                      className="inpt-srchContacto"
                      type="text"
                      value={SrchContact}
                      onChange={(e) => setSrchContact(e.target.value)}
                    />
                  </div>

                  <button className="btn-srchContacts">
                    <img
                      draggable={false}
                      style={{ width: "20px" }}
                      src={lupa}
                    />
                  </button>
                </div>

                {/* <div className="infos-cliente">
                  <div className="infos-contactos">
                    <p className="txt-infos">Cliente:</p>
                    <p className="txt-infos">{Data?.nombrecompleto}</p>
                  </div>
                </div> */}
              </div>

              <button
                className="btn-add"
                onClick={() => openModalUnitContacts(0)}
              >
                <img
                  draggable={false}
                  className="image-add"
                  src={Cruz}
                  alt=""
                />
              </button>
            </div>

            <div className="Ctos-table">
              <div className="tableContainer" style={ResData?.length > 6 ? {height:'85%'} : {}}>
                <div className="Table-head">
                  <div
                    className="TblSizeContacts-clientName"
                    style={{ justifyContent: "left" }}
                  >
                    <p className="txt-head">Nombre</p>
                  </div>

                  {/* <div className="TblSizeContacts-tipoIdent">
                    <p className="txt-head">Identificación</p>
                  </div> */}

                  <div className="TblSizeContacts-NoIdent">
                    <p className="txt-head"># Identificación</p>
                  </div>

                  <div className="TblSizeContacts-vigencia">
                    <p className="txt-head">Vigencia</p>
                  </div>

                  <div className="TblSizeContacts-imgIdent">
                    <p className="txt-head">Imagen</p>
                  </div>

                  <div className="TblSizeContacts-estatus">
                    <p className="txt-head">Estatus</p>
                  </div>

                  <div className="TblSizeContacts-credito">
                    <p className="txt-head">Crédito</p>
                  </div>

                  <div className="tblsizeContacts-dots"></div>
                </div>
                <div
                  className="Table-body"
                  style={
                    ResData?.length === 0
                      ? { justifyContent: "center", height:'10%' }
                      : ResData?.length > 6
                      ? { overflowY: "scroll" }
                      : { }
                  }
                >
                  <div
                    className="nodatadiv"
                    style={ResData?.length === 0 ? {} : { display: "none" }}
                  >
                    <p className="nodata">No hay datos para mostrar</p>
                  </div>
                  {ResData?.map((Contacts, indx) => {
                    return (
                      <div className="BodyElement">
                        <div
                          className="TblSizeContacts-clientName"
                          style={{ justifyContent: "left" }}
                        >
                          <p
                            className="txt-body"
                            style={{ textAlign: "left", marginLeft: "10px" }}
                          >
                            {Contacts.nombrecliente}
                          </p>
                        </div>

                        {/* <div className="TblSizeContacts-tipoIdent">
                          <div className="ImgIdent-cont">
                            
                            <img
                              draggable={false}
                              style={{ width: "32px" }}
                              src={
                                Contacts.tipoidentificacion === null
                                  ? Licencia
                                  : Contacts.tipoidentificacion === "INE"
                                  ? Ine
                                  : Licencia
                              }
                            />
                          </div>
                        </div> */}

                        <div className="TblSizeContacts-NoIdent">
                          <p
                            className="txt-body"
                            style={{ textAlign: "left", marginLeft: "10px" }}
                          >
                            {Contacts.noidentificacion}
                          </p>
                        </div>

                        <div className="TblSizeContacts-vigencia">
                          <p className="txt-body">{Contacts.vigencia}</p>
                        </div>

                        <div
                          className="TblSizeContacts-imgIdent"
                          onClick={() =>
                            console.log(Contacts.tipoidentificacion == null)
                          }
                        >
                          <div className="ImgIdent-cont">
                            <img
                              draggable={false}
                              style={{ width: "40%" }}
                              src={
                                Contacts.tipoidentificacion === null
                                  ? Licencia
                                  : Contacts.tipoidentificacion === "INE"
                                  ? Ine
                                  : Licencia
                              }
                            />
                          </div>
                        </div>

                        
                        <div className="TblSizeContacts-estatus">
                          <div className="Ctos-active" style={Contacts.estatus === 1 ? {background:'#3aae29'} : {background:'#e92828'}}>
                            <p className="txt-activo" >{Contacts.estatus === 1 ? 'Activo' : 'Inactivo'}</p>
                          </div>
                        </div>

                        <div className="TblSizeContacts-credito">
                          <div className="Ctos-credit" style={Contacts.credito === 'Si' ? {background:'#3aae29'} : {background:'#e92828'}}>
                            <p className="txt-credito" > {Contacts.credito}</p>
                          </div>
                        </div>

                        <div className="tblsizeContacts-dots">
                          <div
                            className={
                              ActiveDots === Contacts.id
                                ? "dots-active"
                                : "dots-container"
                            }
                            onClick={() => DotsClicked(Contacts.id)}
                          >
                            <img
                              draggable={false}
                              classname="dooots"
                              src={Dot}
                              alt=""
                            />
                            <img
                              draggable={false}
                              classname="dooots"
                              src={Dot}
                              alt=""
                            />
                            <img
                              draggable={false}
                              classname="dooots"
                              src={Dot}
                              alt=""
                            />
                          </div>
                          <div
                            className="dots-menu"
                            style={
                              ActiveDots === Contacts.id
                                ? indx > RegsPg - 4
                                  ? { bottom: "36px" }
                                  : { display: "block", top: "36px" }
                                : { display: "none" }
                            }
                          >
                            <div className="sm-row"
                            onClick={(e)=>openModalUnitContacts(1, Contacts)}>
                              <div className="sm-img">
                                <img
                                  draggable={false}
                                  className="sm-icon"
                                  style={{ width: "20px" }}
                                  src={Editar}
                                  alt=""
                                />
                              </div>
                              <div className="sm-txt">
                                <p className="txt-submenu">Editar</p>
                              </div>
                            </div>

                            <div className="sm-row">
                              <div
                                className="sm-img"
                                style={{ background: "#F96262" }}
                              >
                                <img
                                  draggable={false}
                                  className="sm-icon"
                                  src={Deleting}
                                  alt=""
                                />
                              </div>
                              <div className="sm-txt">
                                <p className="txt-submenu">
                                  Solicitar inactivación
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="paginador-modal">
                <div className="registros">
                  <div className="regs-slctContainer">
                    <p className="txt-regs">Registros</p>

                    <div
                      className="regs-contained"
                      onMouseLeave={() => setActiveRegs(false)}
                    >
                      <div
                        className="regs-pg"
                        style={ActiveRegs ? {} : { borderRadius: "10px" }}
                      >
                        <div className="regs-selected">
                          <p className="txt-regs">{`${RegsPg}`}</p>
                        </div>
                        <div
                          className="regs-selection"
                          onClick={() => setActiveRegs(!ActiveRegs)}
                          style={
                            ActiveRegs
                              ? { borderRadius: "0px 0px 8px 0px" }
                              : {}
                          }
                        >
                          <img
                            draggable={false}
                            className="regs-select"
                            src={Vopc}
                            alt=""
                            style={
                              ActiveRegs ? { transform: "rotate(180deg)" } : {}
                            }
                          />
                        </div>
                      </div>
                      <div className={ActiveRegs ? "regs-active" : "regs-list"}>
                        <div
                          className="reg"
                          onClick={() => RegsClicked(10)}
                          style={RegsPg === 10 ? { display: "none" } : {}}
                        >
                          10
                        </div>
                        <div
                          className="reg"
                          onClick={() => RegsClicked(15)}
                          style={RegsPg === 15 ? { display: "none" } : {}}
                        >
                          15
                        </div>
                        <div
                          className="reg"
                          onClick={() => RegsClicked(20)}
                          style={RegsPg === 20 ? { display: "none" } : {}}
                        >
                          20
                        </div>
                        <div
                          className="reg"
                          onClick={() => RegsClicked(25)}
                          style={RegsPg === 25 ? { display: "none" } : {}}
                        >
                          25
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="paginador">
                  <div
                    className="prev-plus"
                    onClick={() =>
                      ProPrevPg(
                        `${process.env.REACT_APP_API_URL}Identificacion/getcontacts/${Data.id}?page=${SelectedPg}&registerbypage=${RegsPg}`
                      )
                    }
                    style={
                      SelectedPg === 1
                        ? { background: "#9597a73d", cursor: "unset" }
                        : {}
                    }
                  >
                    <img draggable={false} src={NextI} alt="" />
                    <img draggable={false} src={NextI} alt="" />
                  </div>
                  <div
                    className="prev"
                    onClick={() => PrevPg(SelectedPg)}
                    style={
                      SelectedPg === 1
                        ? { background: "#9597a73d", cursor: "unset" }
                        : {}
                    }
                  >
                    <img draggable={false} src={NextI} alt="" />
                  </div>
                  <div
                    className="pg"
                    onClick={() => test()}
                  >{`${SelectedPg} / ${MaxPg}`}</div>
                  <div
                    className="next"
                    onClick={() => NextPg(SelectedPg)}
                    style={
                      SelectedPg === MaxPg
                        ? { background: "#9597a73d", cursor: "unset" }
                        : {}
                    }
                  >
                    <img draggable={false} src={NextD} alt="" />
                  </div>
                  <div
                    className="next-plus"
                    onClick={() =>
                      ProNextPg(
                        `${process.env.REACT_APP_API_URL}Identificacion/getcontacts/${Data.id}?page=${SelectedPg}&registerbypage=${RegsPg}`
                      )
                    }
                    style={
                      SelectedPg === MaxPg
                        ? { background: "#9597a73d", cursor: "unset" }
                        : {}
                    }
                  >
                    <img draggable={false} src={NextD} alt="" />
                    <img draggable={false} src={NextD} alt="" />
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>

          
          <Modal
            isOpen={ModlUnitContacs}
            style={customSty_UnitContacto}
            onRequestClose={closeModal}
          >
            <UnitContacts
              closeModal={closeModal}
              ModlData={ModlData}
            ></UnitContacts>
          </Modal>

        </div>
      )}
    </>
  );
}

export default Contacts;
