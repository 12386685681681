import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from '../views/dashboard';
import Login from '../views/login';
import Test from '../views/test';
import PrivateRouter from './privateRouter';

import ListCltes from '../components/list-Cltes';
import ListCtosClte from '../components/list-ctosClte'
import ListDirsClte from '../components/list-dirsClte'

import AddClient from '../components/add-cliente';


function Navigation() {
  return (
    <Router>
        <Routes>
            <Route path='/login' element={<Login/>}/>

            <Route exact path='/' element={
                <PrivateRouter>
                    <Dashboard></Dashboard>
                </PrivateRouter>
            }/>

            <Route path='/Clientes' element={
                <PrivateRouter>
                    <ListCltes></ListCltes>
                </PrivateRouter>
            }/>

            <Route path='/Agregar-cliente' element={
                <PrivateRouter>
                    <AddClient></AddClient>
                </PrivateRouter>
            }/>

            
            <Route path='/Editar-cliente/:id' element={
                <PrivateRouter>
                    <AddClient></AddClient>
                </PrivateRouter>
            } />
            
            <Route path='/Cliente-contactos/:id' element={
                <PrivateRouter>
                    <ListCtosClte></ListCtosClte>
                </PrivateRouter>
            } />
            
            <Route path='/Cliente-direcciones/:id' element={
                <PrivateRouter>
                    <ListDirsClte></ListDirsClte>
                </PrivateRouter>
            } />
            
            <Route exact path='/test' element={
                <PrivateRouter>
                    <Test></Test>
                </PrivateRouter>
            } />

        </Routes>
    </Router>
  )
}

export default Navigation