import React from "react";
import { LayoutLoader } from "../themes/layout";
import imgHojaAzul from "../resources/hojaazul.svg";
import imgHojaVerde from "../resources/hojaverde.svg";

const Loader = (props) => {
  return (
    <LayoutLoader style={props?.forcedStys}>
      <div class="loader">
        <div class="loaderImg">
          <div class="duo duo1">
            <div class="dot dot-a">
              {" "}
              <img draggable={false} src={imgHojaVerde} />
            </div>
            <div class="dot dot-b">
              {" "}
              <img draggable={false} src={imgHojaAzul} />
            </div>
          </div>
          <div class="duo duo2">
            <div class="dot dot-a">
              {" "}
              <img draggable={false} src={imgHojaAzul} />
            </div>
            <div class="dot dot-b">
              {" "}
              <img draggable={false} src={imgHojaVerde} />
            </div>
          </div>
        </div>
        <div className="loaderTexto">
          <p>{props.tittle}...</p>
        </div>
      </div>
    </LayoutLoader>
  );
};

export default Loader;
