import React from "react";

function Footer() {
  return (
    <div className="footer">
      <p>Versión 0.0.4</p>
      <p>Desarrollado por PKT1 Developer - Cru</p>
    </div>
  );
}

export default Footer;
