import React from 'react'
import Home from '../components/home'
function Dashboard() {
  return (
    <div className='Dashboard'>
      <Home></Home>
    </div>
  )
}

export default Dashboard