import React from "react";
import "../themes/home.css";
import Imgad from "../resources/add-image.png";

function Home() {
  return (
    <div className="home-container">
      <div className="fast-access">
        <div className="direct">
          <img draggable={false} className="img-direct" src={Imgad} alt="" />
          <p className="txt-direct">Texto 1 </p>
        </div>

        <div className="direct">
          <img draggable={false} className="img-direct" src={Imgad} alt="" />
          <p className="txt-direct">Texto 2 </p>
        </div>

        <div className="direct">
          <img draggable={false} className="img-direct" src={Imgad} alt="" />
          <p className="txt-direct">Texto 3 </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
