import React, { useEffect, useState } from "react";
import { LayoutModals } from "../themes/layout";

import X from "../resources/Equis.svg";
import Mx from "../resources/Flags/mx.svg";
import DVopc from "../resources/UveDarker.svg";
import Btn from "./cstm-btn";
import axios from "axios";

function UnitDirection(props) {
  // console.log(props, "props, recived");
  const [DirData, setDirData] = useState()
  const [Datos, setDatos] = useState(props.ModlData.clientData);
  const [Modo, setModo] = useState(props.ModlData.Mode);
  const [OpcCountry, setOpcCountry] = useState(false);

  // DATOS DIRECCIÓN
  const [Country, setCountry] = useState();
  const [SrchCityCP, setSrchCityCP] = useState("");
  const [Pais, setPais] = useState("");
  const [Estado, setEstado] = useState();
  const [MunDeleg, setMunDeleg] = useState("");
  const [Ciudad, setCiudad] = useState("");
  const [CP, setCP] = useState("");
  const [Colonia, setColonia] = useState("");
  const [Calle, setCalle] = useState("");
  const [NumInt, setNumInt] = useState("");
  const [NumExt, setNumExt] = useState("");

  // console.log(Modo);
  useEffect(() => {
    if (Modo === 1) {
      // alert(Modo)                              //Cliente/Address/Get/129922/151612
      axios.get(`${process.env.REACT_APP_API_URL}Cliente/Address/Get/${Datos.id}/${Datos.iddireccion}`)
      .then(res=>{
        let Respuesta = res.data[0]
        setDirData(Respuesta)
        setPais(Respuesta.pais)
        setCalle(Respuesta.calle)
        setNumExt(Respuesta.noext)
        setNumInt(Respuesta.noint)
        setCP(Respuesta.cp)
        setColonia(Respuesta.colonia)
        setCiudad(Respuesta.localidad)
        setEstado(Respuesta.estado)
        setMunDeleg(Respuesta.municipio)

      })
      .catch(err=>{
        console.log(err)
      })
    }
  
    
  }, [])
  
  return (
    <LayoutModals>
      <div className="Modal-UnitDirection">
        <div className="ModalUnitDirs-head" style={{ width: "100%" }}>
          <div className="headCinta-Units">
            <p className="txt-headUnitDirs">
              {Modo === 0 ? "Agregar dirección" : "Editar dirección"}
            </p>
            <div className="closerImg">
              <img
                className="img-closer"
                onClick={() => props.closeModal()}
                src={X}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="ModalUnitDirs-body">
          <div className="infos-UnitDirection">
            <div className="dta-Gnral-infoShow-Status">
              <p className="txt-infoShow">Estatus:</p>{" "}
              <p className="txt-infoShow">{DirData?.statuscomision ? DirData.statuscomision : 'Nuevo'}</p>
            </div>

            <div className="dta-Gnral-infoShow-Asesor">
              <p className="txt-infoShow">Asesor:</p>{" "}
              <p className="txt-infoShow">{"Carlos Humberto Soto Reyes"}</p>
            </div>
          </div>

          <div className="dta-Direct">
            <div className="dta-Direct-01">
              <div className="dta-Direct-cityCP">
                <div className="dta-Direct-city">
                  <div className="Slct-country">
                    <p>País</p>

                    <div
                      className="cstmSlct-country"
                      onMouseLeave={() => setOpcCountry(false)}
                      onClick={() => setOpcCountry(!OpcCountry)}
                    >
                      <div className="flex-country">
                        <div className="SlctdOpc-country">
                          <p className="txt-Slcted">
                            <img
                              style={{ width: "30px", borderRadius: "2px" }}
                              src={Mx}
                              alt=""
                            />{" "}
                            <p className="txt-pais">{Pais}</p>
                          </p>
                        </div>
                        <div className="btnUpDown-country">
                          <div className="UpDown">
                            <img
                              className="country-updown"
                              src={DVopc}
                              alt=""
                              style={
                                OpcCountry
                                  ? {
                                      transform: "rotate(180deg)",
                                      marginLeft: "0px",
                                    }
                                  : { marginLeft: "0px" }
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="Slction-country"
                        style={
                          OpcCountry === true
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <ul className="cstmList">
                          <li
                            className="cstmLisit-lmnt"
                            onClick={() => setPais("México")}
                          >
                            <img
                              style={{ width: "30px", borderRadius: "2px" }}
                              src={Mx}
                              alt=""
                            />{" "}
                            Mexico (México) +52
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="srch-CityCP">
                    <p>Buscar por Código Postal, Ciudad o Colonia</p>
                    <input type="text" className="inpt-cidyCP" />
                  </div>
                </div>
              </div>
            </div>

            <div className="separator-Direct">
              <div className="form-separator-dtaDirect">
                Información de dirección
              </div>{" "}
              {/* |||||||||||||||||||||||||||||||||||||||||||||||| */}
            </div>

            <div className="dta-Direct-03">
              <div className="dta-Direct-calle">
                <p>Calle</p>
                <input value={Calle} className="inpt-calle" type="text" />
              </div>

              <div className="dta-Direct-nums">
                <div className="dta-Direct-numsExtInt">
                  <p>Número Ext.</p>
                  <input
                  value={NumExt}
                    className="inpt-numsExtInt"
                    type="text"
                    maxLength={6}
                  />
                </div>
                <div className="dta-Direct-numsExtInt">
                  <p>Número Int.</p>
                  <input
                  value={NumInt}
                    className="inpt-numsExtInt"
                    type="text"
                    maxLength={6}
                  />
                </div>
              </div>
            </div>

            <div className="dta-Direct-04">
              <div className="dta-Direct-cp">
                <p>CP</p>
                <input value={CP} type="text" className="inpt-cp" maxLength={5} />
              </div>

              <div className="dta-Direct-colonia">
                <p>Colonia</p>
                <input value={Colonia} className="inpt-colonia" type="text" />
              </div>
            </div>

            <div className="dta-Direct-02">
              <div className="dta-Direct-info">
                <div className="dta-Direct-Geo">
                  <p>Ciudad:</p>
                  <input value={Ciudad} className="inpt-Geo" type="text" name="" id="" />
                </div>

                <div className="dta-Direct-Geo">
                  <p>Estado / Localidad:</p>
                  <input value={Estado} className="inpt-Geo" type="text" name="" id="" />
                </div>
              </div>
              <div className="dta-Direct-info">
                <div className="dta-Direct-Geo">
                  <p>Municipio/Delegación:</p>
                  <input value={MunDeleg} className="inpt-Geo" type="text" />
                </div>
              </div>
            </div>
          </div>

          <div className="btns">
            <Btn btnColor={"green"} btnDescript={"Guardar"}></Btn>
            {/* <Btn btnColor={'red'} btnDescript={'Atras'} onClick={()=> alert('a')}></Btn> */}
          </div>
        </div>
      </div>
    </LayoutModals>
  );
}

export default UnitDirection;
