import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";


import "../themes/add_client.css";
import Vopc from "../resources/Uve.svg";
import DVopc from "../resources/UveDarker.svg";
import Bopc from "../resources/UveBlue.svg";
import Btn from "./cstm-btn";
import Mx from "../resources/Flags/mx.svg";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import Anloader from '../components/AnLoader'

function AddClient(props) {
  const navigate = useNavigate();
  const Param = useParams()

  //CASO EDITAR (PARAMETER DETECTED)
  const [ClteID, setClteID] = useState(Param.id)
  const [Data, setData] = useState()
  const hoyFch = moment().format("YYYY-MM-DD");
  const [Sexos, setSexos] = useState([{"descripcion":'Masculino'}, {"descripcion":'Femenino'}, {"descripcion":'Empresa'}])
  const [Avisoso, setAvisoso] = useState([{"descripcion": 'Llamada telefónica'}, {"descripcion": 'Correo electrónico'}, {"descripcion": 'Mensaje de texto'}])

  //Bandera preloader ?
  const [LoadingDatos, setLoadingDatos] = useState(false)

  // BANDERAS SELECTS
  const [OpcGiro, setOpcGiro] = useState(false);
  const [OpcAviso, setOpcAviso] = useState(false);
  const [OpcCapt, setOpcCapt] = useState(false);
  const [OpcAge, setOpcAge] = useState(false);
  const [OpcSex, setOpcSex] = useState(false);
  const [OpcOrigen, setOpcOrigen] = useState(false);
  const [OpcRegim, setOpcRegim] = useState(false);
  const [OpcCFDI, setOpcCFDI] = useState(false);
  const [OpcCountry, setOpcCountry] = useState(false);
  const [OpcPais, setOpcPais] = useState(false);

  // BANDERAS SWITCHES
  const [SwchAcuse, setSwchAcuse] = useState(false);
  const [SwchAnticipo, setSwchAnticipo] = useState(false);
  const [SwchFactAnticip, setSwchFactAnticip] = useState(false);
  const [SwchImpClaus, setSwchImpClaus] = useState(false);
  const [SwchActivo, setSwchActivo] = useState(false);

  // CHECK PUBLICO GENERAL
  const [ChekGnralPublic, setChekGnralPublic] = useState(false);

  // RESPONSES VALUES
  const [Rescfdis, setRescfdis] = useState();
  const [ResRegims, setResRegims] = useState();

  // VALORES INPUTS
  // LOCKS
  const [LockRFC, setLockRFC] = useState(false);
  // DATOS FACTURACIÓN
  const [RazoName, setRazoName] = useState("");
  const [rfc, setrfc] = useState("");
  const [Regimen, setRegimen] = useState();
  const [CFDI, setCFDI] = useState({key: "G03", description: "Gastos en general",});
  const [ClveSat, setClveSat] = useState("");
  const [Tel, setTel] = useState("");
  const [Mail, setMail] = useState("");
  // DATOS DIRECCIÓN
  const [Country, setCountry] = useState();
  const [SrchCityCP, setSrchCityCP] = useState("");
  const [Pais, setPais] = useState("");
  const [Estado, setEstado] = useState();
  const [MunDeleg, setMunDeleg] = useState("");
  const [Ciudad, setCiudad] = useState("");
  const [CP, setCP] = useState("");
  const [Colonia, setColonia] = useState("");
  const [Calle, setCalle] = useState("");
  const [NumInt, setNumInt] = useState("");
  const [NumExt, setNumExt] = useState("");
  // DATOS GENERALES
  const [Giro, setGiro] = useState();
  const [AvisoEntrega, setAvisoEntrega] = useState();
  const [Captacion, setCaptacion] = useState();
  const [Edad, setEdad] = useState();
  const [Sexo, setSexo] = useState();
  const [Origen, setOrigen] = useState();
  const [Status, setStatus] = useState();
  const [FchAlta, setFchAlta] = useState(hoyFch);
  const [Asesor, setAsesor] = useState();

  // FUNCIONES

  const handleChek = () => {
    setChekGnralPublic(!ChekGnralPublic);
    if (!ChekGnralPublic) {
      setrfc("XAXX010101000");
      setLockRFC(true);
    } else {
      setrfc("");
      setLockRFC(false);
    }
  };

  const GoBack = () => {
    Swal.fire({
      title: "Aviso",
      text: "Los datos se perderan, ¿volver atras?",
      icon: "warning",
      position: "top",
      confirmButtonText: "Aceptar",
      showDenyButton: true,
      denyButtonText: "Cancelar",
      confirmButtonColor: "#52C150",
    }).then((res) => {
      if (res.isConfirmed) {
        navigate("/Clientes");
      }
    });
  };

  const ReqCFDIs = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}Sat/GetUsesCFDIAll`)
      .then((res) => {
        setRescfdis(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ReqRegims = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}Sat/GetFiscalRegimeAll`)
      .then((res) => {
        setResRegims(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };







  //importante funcion para controlar la seleccion con tabulación
  const slctKeyDown = (e, cstmSlct) => {
    // KeyCodes
    // 9  --> TAB             Tabulacion
    // 13 --> Enter             Open
    // 16 --> Shift           Special
    // 27 --> ESC               Close
    // 32 --> Space             Open
    // 38 --> UpArrow           ↑
    // 40 --> DownArrow         ↓


    if (e.keyCode === 13 || 32 || 38 || 40) {
      e.preventDefault();
    }
    let tabsIndx = document.querySelectorAll('[tabindex]'); //Todos los elementos seleccionables
    // console.log('Alltabs', tabsIndx)
    let Element = document.getElementsByClassName(e.target.className); //Elemento seleccionado actualmente

    if (e.keyCode === 13  || e.keyCode === 32 || e.keyCode === 40) { //Eventos SPACE y ENTER, Abrir opciones
      if (Element[0] === document.activeElement) {
        
    console.log(e)
        ModifyCstmSlct(cstmSlct, true)
        
      let currentTabIndex = parseInt(document.activeElement.tabIndex)
      let nextTabIndex = e.shiftKey ? currentTabIndex - 1 : currentTabIndex + 1
      
      tabsIndx.forEach(tabIndx => {
        if (tabIndx.tabIndex === nextTabIndex) {
          setTimeout(() => {
          tabIndx.focus()
          }, 1);
        }
      });



      }
    }else if (e.keyCode === 9 ) { //Evento TAB, Moverte adelante o atras
     moveFocus100(e)

      
    }else if (e.keyCode === 27) { //Evento ESC, Cerrar opciones
      ModifyCstmSlct(cstmSlct, false)
    }
  };

  const InsideNav = (e, cstmSlct) =>{
    console.log('InsideNav')
    
    // KeyCodes
    // 9  --> TAB             Tabulacion
    // 13 --> Enter             Open
    // 16 --> Shift           Special
    // 27 --> ESC               Close
    // 32 --> Space             Open
    // 38 --> UpArrow           ↑
    // 40 --> DownArrow         ↓

    if (e.keyCode === 13 || 32 || 38 || 40) {
      e.preventDefault();
    }

    if (e.keyCode === 38 || e.keyCode === 40) { //
      
      moveFocus1(e)
    }else if (e.keyCode === 32) { //Seleccionar un elemento del submenu
      subClicked(e)
    }else if (e.keyCode === 27) { //Cerrar opciones
      ModifyCstmSlct(cstmSlct, false)
    }
  }

  const subClicked = (e) =>{
    e.preventDefault()
    
    let tabsIndx = document.querySelectorAll('[tabindex]'); //Todos los elementos seleccionables
    let Element = document.getElementsByClassName(e.target.className); //Elemento seleccionado actualmente
    let currentTabIndex = parseInt(document.activeElement.tabIndex)

    tabsIndx.forEach(tabIndx => {
      if (tabIndx.tabIndex === currentTabIndex) {
        tabIndx.click()
        let newfocus = tabIndx.tabIndex.toString()
        if (newfocus.length === 4) {
          newfocus = parseInt(tabIndx.tabIndex.toString()[0] + tabIndx.tabIndex.toString()[1] + '00') + 100 //Realizar focus al siguiente elemento padre
        }else{
          newfocus = parseInt((tabIndx.tabIndex.toString()[0] + '00')) + 100 //Realizar focus al siguiente elemento padre
        }
        document.querySelector(`[tabindex="${newfocus}"]`).focus()
      }
    });
  }
  const moveFocus1 = (e) =>{
      
      let tabsIndx = document.querySelectorAll('[tabindex]'); //Todos los elementos seleccionables
      let Element = document.getElementsByClassName(e.target.className); //Elemento seleccionado actualmente
      let currentTabIndex = parseInt(document.activeElement.tabIndex)
      let nextTabIndex = e.keyCode === 38 ? currentTabIndex - 1 : currentTabIndex + 1
      tabsIndx.forEach(tabIndx => {
        if (tabIndx.tabIndex === nextTabIndex) {
          tabIndx.focus()
        }
      });
  }
  const moveFocus100 = (e) =>{
      
    let tabsIndx = document.querySelectorAll('[tabindex]'); //Todos los elementos seleccionables
    let Element = document.getElementsByClassName(e.target.className); //Elemento seleccionado actualmente
    let currentTabIndex = parseInt(document.activeElement.tabIndex)
    let nextTabIndex = e.shiftKey ? currentTabIndex - 100 : currentTabIndex + 100

    tabsIndx.forEach(tabIndx => {
      if (tabIndx.tabIndex === nextTabIndex) {
        tabIndx.focus()
      }
    });
  }











  const ModifyCstmSlct = (activeIndx, moody) =>{
    switch (activeIndx) {
      case 'Regim': 
      setOpcRegim(moody);
      document.querySelector(`[tabindex="${400}"]`).focus()
      
        break;

      case 'cfdi': 
      setOpcCFDI(moody)
      document.querySelector(`[tabindex="${500}"]`).focus()
        break;

        
      case 'pais': 
      setOpcPais(moody)
      document.querySelector(`[tabindex="${900}"]`).focus()
        break;


      case 'aviso': 
      setOpcAviso(moody)
      document.querySelector(`[tabindex="${2000}"]`).focus()
        break;
        
      case 'sex': 
      setOpcSex(moody)
      document.querySelector(`[tabindex="${2300}"]`).focus()
        break;
    

      default:
        break;
    }
    
  }
  const formatNumber = (num) =>{
    num += 1
    return(num.toString().padStart(2,'0'))
  }
  const formatDMY = (fecha) =>{
    return(moment(fecha).format("DD-MM-YYYY"))
  }
  useEffect(() => {
    if (Param.id) {
      
      setLoadingDatos(true)
      axios.get(`${process.env.REACT_APP_API_URL}Cliente/Get/${ClteID}`)
      .then((res) => {
        setData(res.data);
        setRazoName(res.data.nombrecompleto)
        setrfc(res.data.rfc)
        setRegimen(res.data.regFiscal)
        // setCFDI(res.data.cfdi)
        setClveSat(res.data.claveSat)
        setTel(res.data.telefono)
        setMail(res.data.correo)
        setPais(res.data.pais)
        setCalle(res.data.calle)
        setNumExt(res.data.noext)
        setNumInt(res.data.noint)
        setCP(res.data.cp)
        setColonia(res.data.colonia)
        setCiudad(res.data.ciudad)
        setEstado(res.data.estado)
        setMunDeleg(res.data.municipio)
        setGiro(res.data.giro)
        setAvisoEntrega(res.data.aviso)
        // setCaptacion(res.data.captacion)
        setEdad(res.data.edad)
        setSexo(res.data.sexo)
        // setOrigen(res.data.origen)

        setFchAlta(res.data.fechaalta)

        setSwchAcuse(true)


      
        setLoadingDatos(false)

      })
      .catch((err) => {
        alert(err.data.response.message);
        setLoadingDatos(false)
      });
    
    }

    ReqRegims();
    ReqCFDIs();
  }, []);

  return (
    <>
      {
        LoadingDatos
        ?
        <Anloader forcedStys={{marginTop:'20%'}} tittle={Param.id ? 'Cargando información' : 'Cargando'}></Anloader>
        :
        
        <div className="Container-body">
        <div className="form-addcliente">
          <div className="form-head" onClick={()=>console.log(Data)}>{Param.id ? 'Editar Cliente' : 'Nuevo Cliente'}</div>
          <div className="form-body">
            <div className="form-separator">Datos facturación</div>{" "}
            {/* |||||||||||||||||||||||||||||||||||||||||||||||| */}
            <div className="data-fact">
              <div className="dtaFct-01">
                <div className="dtaFct-razoName">
                  <p onClick={()=>console.log(RazoName)}>Nombre / Razón social</p>
                  <input
                    type="text"
                    className="inpt-razoName"
                    tabIndex={100}
                    value={RazoName}
                    onChange={(e) => setRazoName(e.target.value)}
                  />
                </div>

                <div className="dtaFct-rfc">
                  <div className="dtaFct-gnrlPublic">
                    <p className="txt-rfc">RFC</p>
                    <div className="chckbx-gnrl">
                      <p>Público General</p>
                      <input
                        tabIndex={200}
                        type="checkbox"
                        className="chekbx"
                        value={ChekGnralPublic}
                        onClick={() => handleChek()}
                      />
                    </div>
                  </div>

                  <input
                    tabIndex={300}
                    type="text"
                    className="inpt-rfc"
                    disabled={LockRFC}
                    value={rfc}
                    onChange={(e) => setrfc(e.target.value)}
                    minLength={12}
                    maxLength={13}
                  />
                </div>
              </div>
              <div className="dtaFct-02">
                <div className="dtaFct-regimen">
                  <p>Régimen fiscal</p>
                  <div
                    className="cstmSlct-Regim"
                    onMouseLeave={() => setOpcRegim(false)}
                    onClick={() => setOpcRegim(!OpcRegim)}
                  >
                    <div
                      className="flex-Regim"
                      tabIndex={400}
                      onKeyDown={(e) => slctKeyDown(e, 'Regim')}
                    >
                      <div className="SlctdOpc">
                        <p className="txt-Slcted">
                          {Regimen ? Regimen.description : "Seleccionar"}
                        </p>
                      </div>
                      <div className="btnUpDown-Regim">
                        <div className="UpDown">
                          <img
                            draggable={false}
                            className="user-updown"
                            src={Vopc}
                            alt=""
                            style={
                              OpcRegim ? { transform: "rotate(180deg)" } : {}
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="Slction-Regim"
                      style={
                        OpcRegim === true
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <ul className="cstmList">
                        {ResRegims?.map((Regim, Rindx) => {
                          return (
                            <li
                              className="cstmLisit-lmnt"
                              tabIndex={4 + formatNumber(Rindx)}
                              
                              onKeyDown={(e) => InsideNav(e, 'Regim')}
                              onClick={(e) => setRegimen(Regim)}
                              
                            >
                              {Regim.description}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="dtaFct-cfdi">
                  <p>Uso CFDI</p>
                  <div
                    className="cstmSlct-Regim"
                    onMouseLeave={() => setOpcCFDI(false)}
                    onClick={() => setOpcCFDI(!OpcCFDI)}
                  >
                    <div 
                      className="flex-cfdi"
                      tabIndex={500}
                      onKeyDown={(e) => slctKeyDown(e, 'cfdi')}
                    >
                      <div className="SlctdOpc">
                        <p className="txt-Slcted">{CFDI.description}</p>
                      </div>
                      <div className="btnUpDown-Regim">
                        <div className="UpDown">
                          <img
                            draggable={false}
                            className="user-updown"
                            src={Vopc}
                            alt=""
                            style={
                              OpcCFDI ? { transform: "rotate(180deg)" } : {}
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="Slction-Regim"
                      style={
                        OpcCFDI === true
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <ul className="cstmList">
                        {Rescfdis?.map((cfdi,cfdIndx) => {
                          return (
                            <li
                              tabIndex={5 + formatNumber(cfdIndx)}
                              className="cstmLisit-lmnt"
                              
                              onKeyDown={(e) => InsideNav(e, 'Regim')}
                              onClick={() => setCFDI(cfdi)}
                            >
                              {cfdi.description}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dtaFct-03">
                <div className="dtaFact-claveSat">
                  <p>Clave SAT</p>
                  <input
                    tabIndex={600}
                    type="text"
                    className="inpt-claveSat"
                    value={ClveSat}
                    onChange={(e) => setClveSat(e.target.value)}
                  />
                </div>
                <div className="gpo-telMail">
                  <div className="dtaFact-tel">
                    <p>Teléfono</p>
                    <input
                      tabIndex={700}
                      type="text"
                      className="inpt-tel"
                      value={Tel}
                      onChange={(e) => setTel(e.target.value)}
                      minLength={10}
                      maxLength={15}
                    />
                  </div>

                  <div className="dtaFact-mail">
                    <p>Correo</p>
                    <input
                      tabIndex={800}
                      type="text"
                      className="inpt-mail"
                      value={Mail}
                      onChange={(e) => setMail(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-separator">Datos dirección</div>{" "}
            {/* |||||||||||||||||||||||||||||||||||||||||||||||| */}
            <div className="dta-Direct">
              <div className="dta-Direct-01">
                <div className="dta-Direct-cityCP">
                  <div className="dta-Direct-city">
                    <div className="Slct-country">
                      <p>País</p>

                      <div
                        className="cstmSlct-country"
                        onMouseLeave={() => setOpcCountry(false)}
                        onClick={() => setOpcCountry(!OpcCountry)}
                      >
                        <div className="flex-country"
                              tabIndex={900}        
                              onKeyDown={(e) => slctKeyDown(e, 'pais')}
                              >
                          <div className="SlctdOpc-country">
                            <p className="txt-Slcted">
                              <img
                                draggable={false}
                                style={{ width: "30px", borderRadius: "2px" }}
                                src={Mx}
                                alt=""
                              />{" "}
                              <p className="txt-pais">{Pais}</p>
                            </p>
                          </div>
                          <div className="btnUpDown-country">
                            <div className="UpDown">
                              <img
                                draggable={false}
                                className="country-updown"
                                src={DVopc}
                                alt=""
                                style={
                                  OpcCountry
                                    ? {
                                        transform: "rotate(180deg)",
                                        marginLeft: "0px",
                                      }
                                    : { marginLeft: "0px" }
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          className="Slction-country"
                          style={
                            OpcCountry === true
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <ul className="cstmList">
                            <li
                              className="cstmLisit-lmnt"
                              nKeyDown={(e) => InsideNav(e, 'Regim')}
                              onClick={() => setPais("México")}
                            >
                              <img
                                draggable={false}
                                style={{ width: "30px", borderRadius: "2px" }}
                                src={Mx}
                                alt=""
                              />{" "}
                              Mexico (México) +52
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="srch-CityCP">
                      <p>Buscar por Código Postal, Ciudad o Colonia</p>
                      <input type="text" className="inpt-cidyCP" 
                      tabIndex={1000}
                        />
                    </div>
                  </div>
                </div>
              </div>

              <div className="separator-Direct">
                <div className="form-separator-dtaDirect">
                  Información de dirección
                </div>{" "}
                {/* |||||||||||||||||||||||||||||||||||||||||||||||| */}
              </div>

              <div className="dta-Direct-03">
                <div className="dta-Direct-calle">
                  <p>Calle</p>
                  <input className="inpt-calle" type="text"
                  value={Calle}
                   tabIndex={1100}
                    />
                </div>

                <div className="dta-Direct-nums">
                  <div className="dta-Direct-numsExtInt">
                    <p>Número Ext.</p>
                    <input
                      tabIndex={1200}
                      value={NumExt}
                      className="inpt-numsExtInt"
                      type="text"
                      maxLength={6}
                    />
                  </div>
                  <div className="dta-Direct-numsExtInt">
                    <p>Número Int.</p>
                    <input
                      tabIndex={1300}
                      value={NumInt}
                      className="inpt-numsExtInt"
                      type="text"
                      maxLength={6}
                    />
                  </div>
                </div>
              </div>

              <div className="dta-Direct-04">
                <div className="dta-Direct-cp">
                  <p>CP</p>
                  <input type="text" className="inpt-cp" maxLength={5} 
                  value={CP}
                  tabIndex={1400}
                    />
                </div>

                <div className="dta-Direct-colonia">
                  <p>Colonia</p>
                  <input className="inpt-colonia" type="text" 
                  value={Colonia}
                  tabIndex={1500}
                  />
                </div>
              </div>

              <div className="dta-Direct-02">
                <div className="dta-Direct-info">
                  <div className="dta-Direct-Geo">
                    <p>Ciudad:</p>
                    <input className="inpt-Geo" type="text" name="" id="" 
                    value={Ciudad}
                    tabIndex={1600}
                    />
                  </div>

                  <div className="dta-Direct-Geo">
                    <p>Estado / Localidad:</p>
                    <input className="inpt-Geo" type="text" name="" id="" 
                    value={Estado}
                    tabIndex={1700}
                    />
                  </div>
                </div>
                <div className="dta-Direct-info">
                  <div className="dta-Direct-Geo">
                    <p>Municipio/Delegación:</p>
                    <input className="inpt-Geo" type="text" 
                    value={MunDeleg}
                    tabIndex={1800}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-separator">Datos generales</div>{" "}
            {/* |||||||||||||||||||||||||||||||||||||||||||||||| */}
            <div className="dta-Gnral">
              <div className="dta-Gnral-01">
                <div className="dta-GnralSlct">
                  <p>Giro</p>
                  <div
                    className="cstmSlct-01"
                    onMouseLeave={() => setOpcGiro(false)}
                    onClick={() => setOpcGiro(!OpcGiro)}
                  >
                    <div className="flex-01"
                    tabIndex={1900}>
                      <div className="SlctdOpc">
                        <p className="txt-Slcted">
                          {Giro ? Giro : "Seleccionar"}
                        </p>
                      </div>
                      <div className="btnUpDown-01">
                        <div className="UpDown">
                          <img
                            draggable={false}
                            className="user-updown"
                            src={Vopc}
                            alt=""
                            style={
                              OpcGiro ? { transform: "rotate(180deg)" } : {}
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="Slction-01"
                      style={
                        OpcGiro === true
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <ul className="cstmList">
                        <li
                          className="cstmLisit-lmnt"
                          onClick={() => setGiro("Girar")}
                        >
                          Giro
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="dta-GnralSlct">
                  <p>Aviso de entrega</p>
                  <div
                    className="cstmSlct-01"
                    onMouseLeave={() => setOpcAviso(false)}
                    onClick={() => setOpcAviso(!OpcAviso)}
                  >
                    <div className="flex-01"
                    onKeyDown={(e)=>slctKeyDown(e, 'aviso')}
                    tabIndex={2000}>
                      <div className="SlctdOpc">
                        <p className="txt-Slcted">
                          {AvisoEntrega ? AvisoEntrega : "Seleccionar"}
                        </p>
                      </div>
                      <div className="btnUpDown-01">
                        <div className="UpDown">
                          <img
                            draggable={false}
                            className="user-updown"
                            src={Vopc}
                            alt=""
                            style={
                              OpcAviso ? { transform: "rotate(180deg)" } : {}
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="Slction-01"
                      style={
                        OpcAviso === true
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <ul className="cstmList">
                        <li
                          className="cstmLisit-lmnt"
                          onClick={() => setAvisoEntrega("Llamada telefonica")}
                        >
                          Llamada telefónica
                        </li>
                        <li
                          className="cstmLisit-lmnt"
                          onClick={() => setAvisoEntrega("Correo Electrónico")}
                        >
                          Correo Electrónico
                        </li>
                        <li
                          className="cstmLisit-lmnt"
                          onClick={() => setAvisoEntrega("Mensaje de texto")}
                        >
                          Mensaje de texto
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="dta-GnralSlct">
                  <p>Medio de captación</p>
                  <div
                    className="cstmSlct-01"
                    onMouseLeave={() => setOpcCapt(false)}
                    onClick={() => setOpcCapt(!OpcCapt)}
                  >
                    <div className="flex-01"
                    tabIndex={2100}>
                      <div className="SlctdOpc">
                        <p className="txt-Slcted">
                          {Captacion ? Captacion : "Seleccionar"}
                        </p>
                      </div>
                      <div className="btnUpDown-01">
                        <div className="UpDown">
                          <img
                            draggable={false}
                            className="user-updown"
                            src={Vopc}
                            alt=""
                            style={
                              OpcCapt ? { transform: "rotate(180deg)" } : {}
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="Slction-01"
                      style={
                        OpcCapt === true
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <ul className="cstmList">
                        <li
                          className="cstmLisit-lmnt"
                          onClick={() => setCaptacion("Captado")}
                        >
                          Captación 1
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dta-Gnral-02">
                <div className="dta-Gnral-SexAge">
                  <div className="dta-SlctSexAge">
                    <p>Edad</p>
                    <input
                      className="inpt-Edad"
                      type="text"
                      name=""
                      id=""
                      value={Edad}
                      onChange={(e) => setEdad(e.target.value)}
                      maxLength={3}
                      tabIndex={2200}
                    />
                  </div>
                  <div className="dta-SlctSexAge">
                    <p>Sexo</p>
                    <div
                      className="cstmSlct-SexAge"
                      onMouseLeave={() => setOpcSex(false)}
                      onClick={() => setOpcSex(!OpcSex)}
                    >
                      <div className="flex-SexAge"
                      tabIndex={2300}
                      onKeyDown={(e) => slctKeyDown(e, 'sex')}>
                        <div className="SlctdOpc">
                          <p className="txt-Slcted">
                            {Sexo ? Sexo : "Seleccionar"}
                          </p>
                        </div>
                        <div className="btnUpDown-SexAge">
                          <div className="UpDown">
                            <img
                              draggable={false}
                              className="user-updown"
                              src={Vopc}
                              alt=""
                              style={
                                OpcSex ? { transform: "rotate(180deg)" } : {}
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="Slction-SexAge"
                        style={
                          OpcSex === true
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        
                        <ul className="cstmList">
                        {Sexos.map((Sex, SIndx)=>{
                          return(
                            <li
                              className="cstmLisit-lmnt"
                              tabIndex={23 + formatNumber(SIndx)}
                              onKeyDown={(e)=>InsideNav(e, Sex.descripcion)}
                              onClick={() => setSexo(Sex.descripcion)}
                            >
                              {Sex.descripcion}
                            </li>
                          )
                          })}
                        
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dta-SlctGiro">
                  <p>Origen</p>
                  <div
                    className="cstmSlct-SexAge"
                    onMouseLeave={() => setOpcOrigen(false)}
                    onClick={() => setOpcOrigen(!OpcOrigen)}
                  >
                    <div className="flex-SexAge"
                    tabIndex={2400}>
                      <div className="SlctdOpc">
                        <p className="txt-Slcted">
                          {Origen ? Origen : "Seleccionar"}
                        </p>
                      </div>
                      <div className="btnUpDown-SexAge">
                        <div className="UpDown">
                          <img
                            draggable={false}
                            className="user-updown"
                            src={Vopc}
                            alt=""
                            style={
                              OpcOrigen ? { transform: "rotate(180deg)" } : {}
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="Slction-SexAge"
                      style={
                        OpcOrigen === true
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <ul className="cstmList">
                        <li
                          className="cstmLisit-lmnt"
                          onClick={() => setOrigen("Original")}
                        >
                          Origen
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dta-Gnral-03">
                <div className="dta-GnralShows">
                  <div className="dta-Gnral-infoShow-Status">
                    <p className="txt-infoShow">Estatus:</p>{" "}
                    <p className="txt-infoShow">{Param.id ? Data?.activo === 'Si' ? 'Activo' : 'Inactivo' : 'Nuevo'}</p>
                  </div>

                  <div className="dta-Gnral-infoShow">
                    <p className="txt-infoShow">Fecha Alta:</p>{" "}
                    <p className="txt-infoShow">{formatDMY(FchAlta)}</p>
                  </div>
                </div>

                <div className="dta-Gnral-infoShow-Asesor">
                  <p className="txt-infoShow">Asesor:</p>{" "}
                  <p className="txt-infoShow">{"Carlos Humberto Soto Reyes"}</p>
                </div>
              </div>
            </div>
            <div className="form-separator">Configuración cliente</div>{" "}
            {/* |||||||||||||||||||||||||||||||||||||||||||||||| */}
            <div className="dta-ClientConfig-switches">
              <div className="dta-ClientConfig">
                <div className="btn-switch">
                  <p className="txt-switch">Acuse digital:</p>
                  <label class="switch">
                    <input
                      type="checkbox"
                      onChange={() => setSwchAcuse(!SwchAcuse)}
                      tabIndex={2400}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>

                <div className="btn-switch">
                  <p className="txt-switch">Anticipo:</p>
                  <label class="switch">
                    <input
                      type="checkbox"
                      onChange={() => setSwchAnticipo(!SwchAnticipo)}
                      tabIndex={2500}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>

              <div className="dta-ClientConfig">
                <div className="btn-switch">
                  <p className="txt-switch">Facturar a petición:</p>
                  <label
                    class="switch"
                  >
                    <input type="checkbox" 
                    onChange={() => setSwchFactAnticip(!SwchFactAnticip)}
                    tabIndex={2600}/>
                    <span class="slider round"></span>
                  </label>
                </div>

                <div className="btn-switch">
                  <p className="txt-switch">Impresión clausulado:</p>
                  <label
                    class="switch"
                  >
                    <input type="checkbox" 
                    onChange={() => setSwchImpClaus(!SwchImpClaus)}
                    tabIndex={2700}/>
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>

              <div className="dta-ClientConfig">
                <div className="btn-switch">
                  <p className="txt-switch">Activo:</p>
                  <label class="switch">
                    <input
                      type="checkbox"
                      onChange={() => setSwchActivo(!SwchActivo)}
                      tabIndex={2800}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="btns">
              <Btn btnColor={"green"} btnDescript={"Guardar"}></Btn>
              <Btn
                btnColor={"red"}
                btnDescript={"Atras"}
                Clicked={GoBack}
              ></Btn>
            </div>
          </div>
        </div>
      </div>
      }
    </>
  );
}

export default AddClient;
