import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import "../themes/sidebar.css";
import Logo from "../resources/pkt1green.svg";
import Inicio from "../resources/inicio.svg";
import InicioActive from "../resources/inicio-active.svg";
import Catalogo from "../resources/catalog.svg";
import CatalogoActive from "../resources/catalog-active.svg";
import Docu from "../resources/docus.svg";
import DocuActive from "../resources/docus-active.svg";
import Admin from "../resources/admin.svg";
import AdminActive from "../resources/admin-active.svg";
import Lupa from "../resources/lupa.svg";
import LupaActive from "../resources/lupa-active.svg";
import Recep from "../resources/recep.svg";
import RecepActive from "../resources/recep-active.svg";
import Swal from "sweetalert2";
import { LayoutSidebar } from "../themes/layout";

function Sidebar() {
  const navigate = useNavigate();

  const [LastSelected, setLastSelected] = useState(
    localStorage.getItem("LastSelected")
  );
  const [SubmenuMouse, setSubmenuMouse] = useState();

  const handleLastSelected = (data) => {
    setSubmenuMouse("");
    setLastSelected(data);
    localStorage.setItem("LastSelected", JSON.stringify(data));
  };

  const MouseIn = (data) => {
    // Esta funcion se activara cuando el mouse pase por encima de alguna de las opciones del submenu, para activar su submenu desplegable
    setSubmenuMouse(data);
    // if (data === 'inicio') {

    // } else if (data === 'catalogo'){
    //     setSubmenuMouse(data)
    // } else if (data === 'documentacion') {
    //     setSubmenuMouse(data)
    // }else if (data === 'administracion') {
    //     setSubmenuMouse(data)
    // }else if (data === 'recepcion') {
    //     setSubmenuMouse(data)
    // }else if (data === 'consulta') {
    //     setSubmenuMouse(data)
    // }
  };
  const MouseOut = (data) => {
    // Esta funcion se activara cuando el mouse salga de encima de la opcion del submenu que lo activo, para cerrar el submenu desplegable

    setSubmenuMouse("");

    // if (data === 'inicio') {

    // } else if (data === 'catalogo'){
    //     setSubmenuMouse(LastSelected)
    // } else if (data === 'documentacion') {
    //     setSubmenuMouse(LastSelected)
    // } else if (data === 'administracion') {
    //     setSubmenuMouse(LastSelected)
    // } else if (data === 'recepcion') {
    //     setSubmenuMouse(LastSelected)
    // } else if (data === 'consulta') {
    //     setSubmenuMouse(LastSelected)
    // }
  };
  const SubClicked = (subMenu, smClick) => {
    // Esta funcion se activara cuando el usuario selecicone una ocpion del submenu, enviandolo a la pantalla correspondiente y actualizando el estado del ultimo seleccionado
    if (subMenu === "inicio") {
      navigate(smClick);
      handleLastSelected("inicio");
    } else if (subMenu === "cat") {
      if (smClick === "clientes") {
        navigate("/Clientes");
      } else if (smClick === "regimenes") {
        Alertar("En desarrollo");
        // navigate('/ListaRegimenes')
      } else {
        Alertar("Error Inesperado");
      }
      handleLastSelected("catalogo");
    } else if (subMenu === "doc") {
      if (smClick === "turnos") {
        Alertar("En desarrollo");
        // navigate('/Turnos')
      } else if (smClick === "cotdoc") {
        Alertar("En desarrollo");
        // navigate('/Wizard')  ATENCION AL NOMBRE DE LA RUTA
      } else if (smClick === "embalaje") {
        Alertar("En desarrollo");
        // navigate('/VentaEmbalaje')
      } else if (smClick === "factanticipo") {
        Alertar("En desarrollo");
        // navigate('/FacturaAnticipo')
      } else if (smClick === "recepcion") {
        Alertar("En desarrollo");
        // navigate('/Recepcion')
      } else {
        Alertar("Error Inesperado");
      }
      handleLastSelected("documentacion");
    } else if (subMenu === "admin") {
      if (smClick === "cierredia") {
        Alertar("En desarrollo");
        // navigate('/CierreDia')
      } else if (smClick === "entregaalianza") {
        Alertar("En desarrollo");
        // navigate('/EntregaAlianza')
      } else if (smClick === "entregapkt1") {
        Alertar("En desarrollo");
        // navigate('/EntregaPKT1')
      } else {
        Alertar("Error Inesperado");
      }
      handleLastSelected("administracion");

      // }else if (subMenu === 'recep') {
      //     handleLastSelected('recepcion')
    } else if (subMenu === "consulta") {
      if (smClick === "ventamultis") {
      } else if (smClick === "bitacora") {
        Alertar("En desarrollo");
        // navigate('/BitacoraSeguimiento')
      } else if (smClick === "embalaje") {
        Alertar("En desarrollo");
        // navigate('/Embalaje')
      } else if (smClick === "notascargo") {
        Alertar("En desarrollo");
        // navigate('/NotasCargo')
      } else if (smClick === "factanticipo") {
        Alertar("En desarrollo");
        // navigate('/FactsAnticipo')
      } else if (smClick === "peticioncancel") {
        Alertar("En desarrollo");
        // navigate('/PeticionCancelacion')
      } else {
        Alertar("Error Inesperado");
      }
      handleLastSelected("consulta");
    }
  };
  const Alertar = (Titul) => {
    Swal.fire({
      title: `${Titul}`,
      // text: 'Proximamente...',
      icon: "warning",
      position: "top",
      confirmButtonText: "Aceptar",
      confirmButtonColor: "#52C150",
    });
  };

  const Gotest = () => {
    navigate("/test");
  };

  window.addEventListener("beforeunload", (evt) => {
    handleLastSelected(localStorage.getItem("LastSelected"));
  });

  useEffect(() => {
    //Crear una condicion para mantener el sidebar seleccionado antes del reload
    localStorage.setItem("LastSelected", JSON.stringify("inicio"));
    setLastSelected("inicio");
  }, []);
  return (

<LayoutSidebar>

      <div className="Sidebar">
        {/* Imagen Logo */}
        <div className="logo" onDoubleClick={() => Gotest()}>
          <img draggable={false} className="side-logo" src={Logo} alt="" />
        </div>

        {/* Inicio */}
        <div
          className="side-element"
          onClick={() => navigate("/")}
          onMouseEnter={() => MouseIn("inicio")}
          onMouseLeave={() => MouseOut("inicio")}
        >
          {/* Icono */}
          <div
            className={
              LastSelected === "inicio"
                ? "last-element"
                : SubmenuMouse === "inicio"
                ? "selected-element"
                : "element"
            }
            onClick={() => SubClicked("inicio")}
          >
            <img
              draggable={false}
              className="img"
              src={LastSelected === "inicio" ? InicioActive : Inicio}
              alt=""
            />
            <p
              className={
                LastSelected === "inicio" ? "txt-element-active" : "txt-element"
              }
            >
              Inicio
            </p>
          </div>
        </div>

        {/* Catalogos */}
        <div
          className="side-element"
          onMouseEnter={() => MouseIn("catalogo")}
          onMouseLeave={() => MouseOut("catalogo")}
        >
          {/* Submenu */}
          <div
            className="submenu-catalogo"
            style={SubmenuMouse === "catalogo" ? {} : { display: "none" }}
          >
            <li
              className="sm-catalogo"
              onClick={() => SubClicked("cat", "clientes")}
            >
              Clientes
            </li>
            <li
              className="sm-catalogo"
              onClick={() => SubClicked("cat", "regimenes")}
            >
              Regimen fiscal
            </li>
          </div>

          {/* Icono */}
          <div
            className={
              LastSelected === "catalogo"
                ? "last-element"
                : SubmenuMouse === "catalogo"
                ? "selected-element"
                : "element"
            }
          >
            <img
              draggable={false}
              className="img"
              src={LastSelected === "catalogo" ? CatalogoActive : Catalogo}
              alt=""
            />
            <p
              className={
                LastSelected === "catalogo" ? "txt-element-active" : "txt-element"
              }
            >
              Catálogo
            </p>
          </div>
        </div>

        {/* Documentaciones */}
        <div
          className="side-element"
          onMouseEnter={() => MouseIn("documentacion")}
          onMouseLeave={() => MouseOut("documentacion")}
        >
          {/* Submenu */}
          <div
            className="submenu-documentacion"
            style={SubmenuMouse === "documentacion" ? {} : { display: "none" }}
          >
            <li
              className="sm-documentacion"
              onClick={() => SubClicked("doc", "turnos")}
            >
              Turnos
            </li>
            <li
              className="sm-documentacion"
              onClick={() => SubClicked("doc", "cotdoc")}
            >
              Cotizar / Documentar
            </li>
            <li
              className="sm-documentacion"
              onClick={() => SubClicked("doc", "embalaje")}
            >
              Venta embalaje
            </li>
            <li
              className="sm-documentacion"
              onClick={() => SubClicked("doc", "factanticipo")}
            >
              Factura anticipo
            </li>
            <li
              className="sm-documentacion"
              onClick={() => SubClicked("doc", "recepcion")}
            >
              Recepción
            </li>
          </div>

          {/* Icono */}
          <div
            className={
              LastSelected === "documentacion"
                ? "last-element"
                : SubmenuMouse === "documentacion"
                ? "selected-element"
                : "element"
            }
          >
            <img
              draggable={false}
              className="img"
              src={LastSelected === "documentacion" ? DocuActive : Docu}
              alt=""
            />
            <p
              className={
                LastSelected === "documentacion"
                  ? "txt-element-active"
                  : "txt-element"
              }
            >
              Procesos
            </p>
          </div>
        </div>

        {/* Administracion */}
        <div
          className="side-element"
          onMouseEnter={() => MouseIn("administracion")}
          onMouseLeave={() => MouseOut("administracion")}
        >
          {/* Submenu */}
          <div
            className="submenu-administracion"
            style={SubmenuMouse === "administracion" ? {} : { display: "none" }}
          >
            <li
              className="sm-administracion"
              onClick={() => SubClicked("admin", "cierredia")}
            >
              Cierre del día
            </li>
            <li
              className="sm-administracion"
              onClick={() => SubClicked("admin", "entregaalianza")}
            >
              Entregas Alianza
            </li>
            <li
              className="sm-administracion"
              onClick={() => SubClicked("admin", "entregapkt1")}
            >
              Entregas PKT1
            </li>
          </div>

          {/* Icono */}
          <div
            className={
              LastSelected === "administracion"
                ? "last-element"
                : SubmenuMouse === "administracion"
                ? "selected-element"
                : "element"
            }
          >
            <img
              draggable={false}
              className="img"
              src={LastSelected === "administracion" ? AdminActive : Admin}
              alt=""
            />
            <p
              className={
                LastSelected === "administracion"
                  ? "txt-element-active"
                  : "txt-element"
              }
            >
              Admin.
            </p>
          </div>
        </div>
        {/* 
          <div className='side-element' onMouseEnter={() => MouseIn('recepcion')} onMouseLeave={() => MouseOut('recepcion')}>

              
              <div className={LastSelected === 'recepcion' ? 'last-element' : SubmenuMouse === 'recepcion' ? 'selected-element' : 'element'} onClick={() => SubClicked('recep')}>
                  <img draggable={false} className='img' src={LastSelected === 'recepcion' ? RecepActive : Recep} alt=''/>
                  <p className={LastSelected === 'recepcion' ? 'txt-element-active' : 'txt-element'}>Recepción</p>

              </div>

          </div> */}

        {/* Consulta */}
        <div
          className="side-element"
          onMouseEnter={() => MouseIn("consulta")}
          onMouseLeave={() => MouseOut("consulta")}
        >
          {/* Submenu */}
          <div
            className="submenu-consulta"
            style={SubmenuMouse === "consulta" ? {} : { display: "none" }}
          >
            <li
              className="sm-consulta"
              onClick={() => SubClicked("consulta", "ventamultis")}
            >
              Ventas multiservicio
            </li>
            <li
              className="sm-consulta"
              onClick={() => SubClicked("consulta", "bitacora")}
            >
              Bitácora seguimiento
            </li>
            <li
              className="sm-consulta"
              onClick={() => SubClicked("consulta", "notascargo")}
            >
              Notas de cargo
            </li>
            <li
              className="sm-consulta"
              onClick={() => SubClicked("consulta", "embalaje")}
            >
              Embalaje
            </li>
            <li
              className="sm-consulta"
              onClick={() => SubClicked("consulta", "factanticipo")}
            >
              Factura anticipo
            </li>
            <li
              className="sm-consulta"
              onClick={() => SubClicked("consulta", "peticioncancel")}
            >
              Petición cancelación
            </li>
          </div>

          {/* Icono */}
          <div
            className={
              LastSelected === "consulta"
                ? "last-element"
                : SubmenuMouse === "consulta"
                ? "selected-element"
                : "element"
            }
          >
            <img
              draggable={false}
              className="img"
              src={LastSelected === "consulta" ? LupaActive : Lupa}
              alt=""
            />
            <p
              className={
                LastSelected === "consulta" ? "txt-element-active" : "txt-element"
              }
            >
              Consultas
            </p>
          </div>
        </div>
      </div>

</LayoutSidebar>

  );
}

export default Sidebar;
