import React, { useEffect, useState } from "react";
import { LayoutModals } from "../themes/layout";

import moment from "moment";
import Btn from "../components/cstm-btn";
import X from "../resources/Equis.svg";
import AddImg from "../resources/add-image.png";
import PixelSun from "../resources/pixelsun.jpg"

import Licence from "../resources/Licence.svg";
import Ine from "../resources/INE.svg";
import Passport from "../resources/Passport.svg";

import ModlFirmar from "./Modl-Fimar";
import Modal from "react-modal";
import ReactSignatureCanvas from 'react-signature-canvas'
import { LayoutCanvas } from "../themes/layout";
import axios from "axios";

function UnitContacts(props) {
  // console.log('contactprops', props)
  const customSty_Firmar = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none ",
      height: "70%",
      width: "50%",
      background: "#D9D9D9",
      overflow: "none",
      boxShadow: " 0px 0px 10px rgb(128 128 128)",
    },
  };

  const hoyFch = moment().format("YYYY-MM-DD");
  const [Sign, setSign] = useState()
  const [url, setUrl] = useState('')

  const [Datos, setDatos] = useState(props.ModlData.ContactData);
  const [Modo, setModo] = useState(props.ModlData.Mode);


  const [ModlFirmar, setModlFirmar] = useState(false)

  const [NombreCto, setNombreCto] = useState()
  const [SlctIdenType, setSlctIdenType] = useState();
  const [NumIdent, setNumIdent] = useState()
  const [FechaVigencia, setFechaVigencia] = useState();

  const [ImgIdentificacion_Frt, SetImgIdentificacion_Frt] = useState();
  const [ImgIdentificacion_Bck, SetImgIdentificacion_Bck] = useState();
  const [FirmaCliente, setFirmaCliente] = useState()

  


  

  const openFirma = () =>{
    setModlFirmar(true)
  }
  const closeFirma = () =>{
    setModlFirmar(false)
  }

  const Guardar = () =>{
    setUrl(Sign.getTrimmedCanvas().toDataURL())
    setFirmaCliente(Sign.getTrimmedCanvas().toDataURL('image/png'))
    closeFirma()
  }

  const Limpiar = () =>{
    Sign.clear()
    setUrl('')
  }

  

  const UploadFront = (evt) =>{
    let file = evt.target.files[0]

    if (file) {
      
    const Rdr = new FileReader()
    Rdr.onload = (evt) =>{
      SetImgIdentificacion_Frt(evt.target.result)
    }
    Rdr.readAsDataURL(evt.target.files[0])
    }

  }

  const UploadBack = (evt) =>{
    let file = evt.target.files[0]

    if (file) {
      
    const Rdr = new FileReader()
    Rdr.onload = (evt) =>{
      SetImgIdentificacion_Bck(evt.target.result)
    }
    Rdr.readAsDataURL(evt.target.files[0])
    }

  }

  const mostrar = () =>{
    console.log('Front: ', ImgIdentificacion_Frt)
    console.log('Back: ', ImgIdentificacion_Bck)
  }  

  
  useEffect(() => {
    if (Modo === 1) {
      console.log(Datos)
      setNombreCto(Datos.nombrecliente)

      switch (Datos.tipoidentificacion) {
        case 'INE':
            setSlctIdenType(0)
          break;
        case 'Licencia':
            setSlctIdenType(1)
          break;
        case 'Pasaporte':
            setSlctIdenType(2)
          break;
            
        default:
        break;
      }
      setNumIdent(Datos.noidentificacion)
      setFechaVigencia(Datos.vigencia)

      
    }
  
  }, [])

  return (
    <LayoutModals style={{ height: "100%" }}>
      <div className="Modal-UnitContacts">
        <div className="ModalUnitCtcs-head" style={{ width: "100%" }}>
          <div className="headCinta">
            <p className="txt-head" onClick={()=> mostrar()}>
              {Modo === 0 ? "Agregar contacto" : "Editar contacto"}
            </p>
            <div className="closerImg">
              <img
                className="img-closer"
                onClick={() => props.closeModal()}
                src={X}
              />
            </div>
          </div>
        </div>

        <div className="ModalUnitCtcs-body">
          <div className="ModalUnitCtcs-sction-left">
            <div className="IdentImages">
              <p>Click en el cuadro para ingresar imagenes de identificación</p>
              <div class="form-group" align="center">
                <label title="Click para seleccionar imagen frontal" className="lblImg" for="file-inptBack" >
                  {
                    ImgIdentificacion_Frt 
                    ?
                      <img className="img-ident" src={ImgIdentificacion_Frt} alt='test'/>
                    :
                      <p>Agregar imagen frontal</p> 
                  }
                </label>

                <input
                style={{display:'none'}}
                  id="file-inptBack"
                  type="file"
                  accept="image/*"
                  onChange={(e) => UploadFront(e)}
                />
              </div>
              
              <div class="form-group" align="center">
                <label title="Click para seleccionar imagen trasera" className="lblImg" for="file-inptFront" >
                {
                    ImgIdentificacion_Bck
                    ?
                      <img className="img-ident" src={ImgIdentificacion_Bck} alt='test'/>
                    :
                      <p>Agregar imagen trasera</p> 
                  }
                </label>

                <input
                  style={{display:'none'}}
                  id="file-inptFront"
                  type="file"
                  accept="image/*"
                  onChange={(e) => UploadBack(e)}
                />
              </div>

            </div>

            <div className="SignImage">
              <div className="Sign-img">
                <p className="txt-areaImg">Click en el cuadro para ingresar firma</p>
                {/* <Canvas></Canvas> */}
                <div className="areaImgSign" onClick={(e)=>openFirma()}>
                  {
                    FirmaCliente
                    ?
                    <img className="img-firma" src={FirmaCliente} />
                    :
                    <p>Agregar firma</p>
                  }
                  

                  {/* <img
                    draggable={false}
                    className="img-IdentSign"
                    src={AddImg}
                    alt=""
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="ModalUnitCtcs-section-right">
            <div className="container datos">
              <div className="in-clientName">
                <p>Nombre</p>
                <input className="inpt-clientName" type="text" value={NombreCto}/>
              </div>
              <div className="in-IdenType">
                <p>Identificación</p>
                <div className="container-IdentType">
                  <div
                    className={SlctIdenType === 0 ? 'IdentType-Selected' : 'IdentType'}
                    // style={SlctIdenType === 0 ? { background: "#B4DBB0" } : {}}
                    onClick={() => setSlctIdenType(0)}
                  >
                    <img
                      draggable={false}
                      className="img-IdentSign"
                      style={{ width: "70%" }}
                      src={Ine}
                    />
                  </div>

                  <div
                    className={SlctIdenType === 1 ? 'IdentType-Selected' : 'IdentType'}
                    // style={SlctIdenType === 1 ? { background: "#B4DBB0" } : {}}
                    onClick={() => setSlctIdenType(1)}
                  >
                    <img
                      draggable={false}
                      className="img-IdentSign"
                      style={{ width: "70%" }}
                      src={Licence}
                    />
                  </div>

                  <div
                    className={SlctIdenType === 2 ? 'IdentType-Selected' : 'IdentType'}
                    // style={SlctIdenType === 2 ? { background: "#B4DBB0" } : {}}
                    onClick={() => setSlctIdenType(2)}
                  >
                    <img
                      draggable={false}
                      className="img-IdentSign"
                      style={{ width: "70%" }}
                      src={Passport}
                    />
                  </div>
                </div>
              </div>

              <div className="in-IdenInfo">
                <div className="num-Ident">
                  <p>No. Identificación</p>
                  <input className="inpt-numIdent" type="text" value={NumIdent}  onChange={(e)=>setNumIdent(e.target.value)}/>
                </div>

                <div className="vigencia-Ident">
                  <p>Vigencia</p>
                  <input
                    className="inpt-vigencia"
                    type="date"
                    value={FechaVigencia}
                    onChange={(e) => setFechaVigencia(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="btn-accept">
              <Btn
                btnColor={"green"}
                btnDescript={"Guardar"}
                style={{ width: "30%" }}
              ></Btn>
            </div>
          </div>
        </div>

        <Modal isOpen={ModlFirmar} style={customSty_Firmar} onRequestClose={closeFirma}>
          <LayoutCanvas>
            <div className="Canvas" style={{height:'100%'}}>

              <div className="titulCinta">
                <p className="txt-head" onClick={()=> mostrar()}>
                  Agregar firma
                </p>
                <div className="closerImg" style={{cursor:'pointer'}}>
                  <img
                    className="img-closer"
                    onClick={() => closeFirma()}
                    src={X}
                  />
                </div>
              </div>

              <div className='container-firma'>
                <div className="areaFirmar">  
                  <ReactSignatureCanvas canvasProps={{width:'700', height: '350', className:'signCanvas'}} ref={(data)=> {setSign(data)}}/>
                </div>
                <div className="btnsFirmar">
                  
                  

                  <Btn 
                  style={{margin:'0% 2%'}}
                    btnColor={"red"}
                    btnDescript={"Limpiar"}
                    Clicked={Limpiar}
                  ></Btn>
                  
                  <Btn 
                  style={{margin:'0% 2%'}}
                    btnColor={"green"}
                    btnDescript={"Guardar"}
                    Clicked={Guardar}
                  ></Btn>
                  
                </div>
                {/* <img src={url}/> */}
              </div>
              
            </div>
          </LayoutCanvas>
        </Modal>
      </div>
    </LayoutModals>
  );
}

export default UnitContacts;
